/* shared-vars.css */

html {
  --main-bg: #f3f3f3; /* rgb: 243,243,243 */
  --primary: #3b2156; /* rgb: 59, 33, 86 */
  --dark-primary: #1d102b;
  --secondary: #ec7157; /* rgb: 236, 113, 87 */
  --tertiary: #2899a0; /* rgb: 40, 153, 160*/
  --tertiary10: #e9f5f5;
  --black: #4a4a4a; /* rgb: 74, 74, 74 */
  --dark-grey: #9f9f9b; /* rgb: 159, 159, 159 */
  --middark-grey: #767676; /* rgb: 118, 118, 188 */
  --disabled: #9f9f9b; /* rgb: 159, 159, 159 */
  --border-grey: #bcbcbc; /* rgb: 188, 188, 188 */
  --mid-grey: #d8d8d8; /* rgb: 216, 216, 216 */
  --midlight-grey: #eeeeee; /* rgb: 238, 238, 238 */
  --light-grey: #f3f3f3; /* rgb: 243,243,243 */
  --error-red: #ed1c24; /* rgb: 237, 28, 36 */
  --valid: #00b011;
  --warning: #f6b424;
  --footer-bg: #2e2c31; /* rgb: 46, 44, 49 */
  --facebook: #1877f2;
  --linkedin: #0a66c2;
  --twitter: #0f1419;
  --gmail: #eA4335;
  --microsoft: #0078d7;
  --youtube: #ff0000;
  --tiktok: #000000;
  --hotukdeals: #485d01;
  --whatsapp: #25D366;
  --muckrack: #101010;
  --instagram: #c32aa3;
  --focus: #d4ebec;
  --stars: #f19900;
}
