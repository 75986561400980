@font-face {
  font-family: 'ldc-manufacturer-icons';
  src: url('../fonts/ldc-manufacturer-icons/ldc-manufacturer-icons.woff2') format('woff2'), url('../fonts/ldc-manufacturer-icons/ldc-manufacturer-icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="brand-"],
[class*=" brand-"] {
  font-family: 'ldc-manufacturer-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brand-abarth:before {
  content: "\e900";
}

.brand-aehra:before {
  content: "\e90a";
}

.brand-aixam:before {
  content: "\e901";
}

.brand-alfa-romeo:before {
  content: "\e902";
}

.brand-alpine:before {
  content: "\e903";
}

.brand-aston-martin:before {
  content: "\e905";
}

.brand-audi:before {
  content: "\e906";
}

.brand-b-on:before {
  content: "\e949";
}

.brand-bentley:before {
  content: "\e907";
}

.brand-bmw:before {
  content: "\e908";
}

.brand-bmw-alpina:before {
  content: "\e909";
}

.brand-byd:before {
  content: "\e936";
}

.brand-caterham:before {
  content: "\e90b";
}

.brand-chevrolet:before {
  content: "\e90c";
}

.brand-citroen:before {
  content: "\e90e";
}

.brand-cupra:before {
  content: "\e90f";
}

.brand-dacia:before {
  content: "\e910";
}

.brand-dfsk:before {
  content: "\e912";
}

.brand-dodge:before {
  content: "\e913";
}

.brand-ds:before {
  content: "\e914";
}

.brand-ferrari:before {
  content: "\e915";
}

.brand-fiat:before {
  content: "\e916";
}

.brand-fisker:before {
  content: "\e948";
}

.brand-ford:before {
  content: "\e917";
}

.brand-genesis:before {
  content: "\e946";
}

.brand-great-wall:before {
  content: "\e918";
}

.brand-gwm-ora:before {
  content: "\e904";
}

.brand-honda:before {
  content: "\e919";
}

.brand-hyundai:before {
  content: "\e944";
}

.brand-ineos:before {
  content: "\e911";
}

.brand-infiniti:before {
  content: "\e91b";
}

.brand-isuzu:before {
  content: "\e91c";
}

.brand-isuzu-trucks:before {
  content: "\e91d";
}

.brand-iveco:before {
  content: "\e91e";
}

.brand-jaecoo:before {
  content: "\e94e";
}

.brand-jaguar:before {
  content: "\e91f";
}

.brand-jeep:before {
  content: "\e920";
}

.brand-kgm:before {
  content: "\e94a";
}

.brand-kia:before {
  content: "\e921";
}

.brand-lamborghini:before {
  content: "\e922";
}

.brand-land-rover:before {
  content: "\e923";
}

.brand-leapmotor:before {
  content: "\e94f";
}

.brand-levc:before {
  content: "\e947";
}

.brand-lexus:before {
  content: "\e924";
}

.brand-lotus:before {
  content: "\e925";
}

.brand-man:before {
  content: "\e926";
}

.brand-maserati:before {
  content: "\e927";
}

.brand-maxus:before {
  content: "\e91a";
}

.brand-mazda:before {
  content: "\e928";
}

.brand-mclaren:before {
  content: "\e945";
}

.brand-mercedes-benz:before {
  content: "\e929";
}

.brand-mg-motor-uk:before {
  content: "\e92a";
}

.brand-mini:before {
  content: "\e92c";
}

.brand-mitsubishi:before {
  content: "\e92d";
}

.brand-morgan:before {
  content: "\e92b";
}

.brand-munro:before {
  content: "\e90d";
}

.brand-nissan:before {
  content: "\e92e";
}

.brand-omoda:before {
  content: "\e94b";
}

.brand-perodua:before {
  content: "\e92f";
}

.brand-peugeot:before {
  content: "\e930";
}

.brand-polestar:before {
  content: "\e943";
}

.brand-porsche:before {
  content: "\e931";
}

.brand-proton:before {
  content: "\e932";
}

.brand-renault:before {
  content: "\e933";
}

.brand-renault-trucks:before {
  content: "\e934";
}

.brand-rolls-royce:before {
  content: "\e935";
}

.brand-seat:before {
  content: "\e937";
}

.brand-seres:before {
  content: "\e94d";
}

.brand-skoda:before {
  content: "\e938";
}

.brand-skywell:before {
  content: "\e94c";
}

.brand-smart:before {
  content: "\e939";
}

.brand-ssangyong:before {
  content: "\e93a";
}

.brand-subaru:before {
  content: "\e93b";
}

.brand-suzuki:before {
  content: "\e93c";
}

.brand-tata:before {
  content: "\e93d";
}

.brand-tesla:before {
  content: "\e93e";
}

.brand-toyota:before {
  content: "\e93f";
}

.brand-vauxhall:before {
  content: "\e940";
}

.brand-volkswagen:before {
  content: "\e941";
}

.brand-volvo:before {
  content: "\e942";
}
