/* ldc-sub-footer.css */


.popular-search {
  color: var(--middark-grey);
}

.popular-search h3 {
  font-size: 24px;
}

.popular-search .ns-carousel-item {
  padding-left: 8px;
  padding-right: 8px;
}

.footer-links {
  width: 100%;
}

.footer-links h3 {
  font-size: 24px;
}

.footer-links .footer-links-inner {
  list-style: none outside none;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
}

.footer-links.col6 a {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  padding: 8px 0;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .footer-links.col6 a {
    flex: 1 1 25%;
    max-width: 25%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer-links.col6 a {
    flex: 1 1 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .footer-links.col6 a {
    flex: 1 1 16.6%;
    max-width: 16.6%;
  }
}

@media (max-width: 767.98px) {

  .popular-search .deal-card .deal-body {
    font-size: 14px;
  }
}
