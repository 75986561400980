/* CSS Document */
.hero-wrapper {
  position: relative;
  width: 100%;
  max-width: 2560px;
  margin: 0 auto;
  overflow: hidden;
  background: var(--primary);
}

.hero-wrapper.hero-rating {
  overflow: auto;
  padding-bottom: 160px;
  background: var(--primary);
}

.hero-wrapper.promo {
  max-width: 1920px;
  margin: 0 auto;
}

.hero-wrapper.hero-lease-deals .image-wrapper {
  height: 360px;
}

.hero-wrapper .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: none;
}

.edge .hero-wrapper .image-wrapper img {
  pointer-events: none;
}

.hero-wrapper.m1920-cc .image-wrapper img {
  object-position: 50% 50%;
}

.hero-wrapper.m1920-rc .image-wrapper img {
  object-position: 0% 50%;
}

.hero-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
}

.hero-overlay {
  width: 100%;
  height: 100%;
  position: relative;
}

.no-overlay .hero-overlay {
  display: none;
}

.top-level .hero-overlay {
  display: none;
}

.light .hero-overlay {
  display: none;
}

.hero-expander-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 1px;
  padding: 20px;
}

.hero-rating-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 1366px;
  min-height: 100%;
  height: 1px;
  padding: 20px;
}

.hero-content ul.breadcrumbs {
  display: none;
  position: absolute;
  top: 10px;
  border-bottom: none;
  z-index: 1;
  padding-left: 2%;
  padding-right: 2%;
}

.hero-content ul.breadcrumbs a,
.hero-content ul.breadcrumbs a::after {
  color: #fff;
}

.light .hero-content ul.breadcrumbs,
.light .hero-content ul.breadcrumbs a,
.light .hero-content ul.breadcrumbs a:after {
  color: var(--primary);
}

.hero-text-content {
  position: absolute;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  justify-content: flex-start;
}

.center .hero-text-content {
  justify-content: flex-start;
}

.home .hero-text-content {
  padding-bottom: 60px;
}

.r-align .hero-text-content {
  right: 0;
  text-align: right;
  padding-left: 0;
  padding-right: 10%;
}

.dream-it .hero-text-content,
.dream-it-hero .hero-text-content {
  width: 100%;
  padding: 0;
}

.hero-title {
  position: relative;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  z-index: 3;
  margin: 0 0 0.4em;
  color: #fff;
  letter-spacing: -0.025rem;
  font-size: 28px;
}

.light .hero-title {
  color: var(--primary);
  text-shadow: none;
}

.dream-it .hero-title {
  padding-top: 36px;
  font-size: 36px;
}

.dream-it-hero .hero-title {
  padding-top: 36px;
}

.dream-it .title-logo {
  font-size: 1.1em;
  color: var(--primary);
  display: none;
  text-shadow: none;
  letter-spacing: -0.1rem;
}

.dream-it.black .title-logo {
  color: #fff;
}

.dream-it .title-logo span {
  font-size: 0.6em;
  color: var(--tertiary);
}

.hero-text-wrap {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px 20px 25px 20px;
  border-radius: 15px;
}

.hero-text.sponsor {
  padding-top: 10px;
  padding-bottom: 20px;
}

.hascta .hero-text {
  margin-bottom: 20px;
}

.light .hero-text p {
  color: var(--primary);
  text-shadow: none;
}

.hero-text p {
  position: relative;
  font-size: 16px;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
  padding: 0 0 1em 0;
  z-index: 3;
  color: #fff;
  line-height: 1.4em;
}

.hero-text p.size2 {
  font-size: 20px;
}

.hero-text p.size3 {
  font-size: 18px;
}

.hero-text a {
  color: #fff;
  text-decoration: underline;
}

.hero-text a:hover,
.hero-text a:focus {
  color: #fff;
  font-weight: bold;
}

.hero-text .small {
  font-size: 0.75em;
}

.hero-link {
  position: relative;
  padding: 0px 15px;
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.75);
  z-index: 3;
  height: 40px;
  line-height: 38px;
  border: 1px solid #fff;
  text-decoration: none;
  transition: color 0.1s, background 0.1s;
  background: rgba(0, 0, 0, 0.4);
  margin-right: 0px;
  margin-bottom: 20px;
  cursor: pointer;
  letter-spacing: -0.1px;
}

@supports (backdrop-filter: blur()) {
  .hero-link {
    backdrop-filter: blur(2px);
  }
}

@supports (-webkit-backdrop-filter: blur()) {
  .hero-link {
    -webkit-backdrop-filter: blur(2px);
  }
}

.hero-link:hover,
.hero-link:focus {
  color: #fff;
  text-decoration: underline;
  background: rgba(0, 0, 0, 0.3);
}

.hero-link:last-of-type {
  margin-right: 0;
  margin-bottom: 20px;
}

.light .hero-link {
  color: var(--primary);
  text-shadow: none;
  background: rgba(255, 255, 255, 0.5);
  border-color: var(--primary);
}

.hero-carexpert-wrap {
  position: relative;
}

.hero-carexpert-wrap .scroll-cta {
  margin-bottom: 20px;
  width: auto;
  height: 50px;
  line-height: 50px;
  padding: 0 16px;
  display: inline-block;
}

.hero-carexpert-wrap .scroll-cta:after {
  content: "\f0ab";
  font-family: rgd-icons;
  margin-left: 5px;
  font-weight: 400;
  font-size: 21px;
}

.hero-carexpert-wrap .count {
  font-size: 16px;
  padding-bottom: 15px;
}

.hero-review-score {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0px);
  margin: 0 auto;
  color: #fff;
  background: var(--tertiary);
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  white-space: nowrap;
}

.hero-review-score .score {
  font-size: 28px;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-right: 10px;
}

.hero-review-score .title {
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  display: block;
}

.hero-review-score .tooltip {
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
}

.hero-review-score .tooltip:after {
  content: "\f29c";
  font-family: rgd-icons;
  margin-left: 5px;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
}

.hero-carexpert-text {
  font-size: 14px;
  padding-bottom: 10px;
  line-height: 1.5em;
  text-shadow: 0px 2px 3px rgb(0, 0, 0, 0.75);
}

.hero-read-more,
.car-leasing-read-more {
  font-size: inherit;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  text-shadow: 0px 2px 3px rgb(0, 0, 0, 0.75);
  display: block;
  margin-bottom: 20px;
}

.top-level .hero-read-more {
  color: #fff;
}

.hero-read-more:hover {
  color: #fff;
  font-weight: bold;
}

.hero-vehicle-image {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.hero-vehicle-image:before {
  content: "";
  z-index: 2;
  top: -12px;
  bottom: 0;
  left: 18px;
  right: 0;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  width: inherit;
  height: inherit;
  transform: scale(0.975);
  border: 6px solid var(--tertiary);
  margin: 0 auto;
}

.hero-vehicle-image img {
  width: 240px;
  height: auto;
  transform: none;
}

.edge .hero-vehicle-image img {
  pointer-events: none;
}

.sponsored {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 2;
  top: 5px;
  right: 10px;
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
}

.mobsmall {
  display: none;
}

.icon-balloon {
  position: absolute;
  left: 100%;
  bottom: 30%;
  animation: drift 40s linear infinite;
  width: 70px;
  height: auto;
  z-index: 2;
}

.icon-balloon2 {
  position: absolute;
  right: 100%;
  top: 18%;
  animation: drift2 50s linear infinite;
  animation-delay: -10s;
  width: 30px;
  height: auto;
}

.icon-heart {
  position: absolute;
  left: 9%;
  top: 26%;
  animation: pulse 5s ease-in infinite;
  width: 70px;
  height: auto;
  transform: scale(0) rotate(-15deg);
}

.icon-heart2 {
  position: absolute;
  right: 3%;
  top: 14%;
  animation: pulse2 5s ease-in infinite;
  animation-delay: 300ms;
  width: 50px;
  height: auto;
  transform: scale(0) rotate(10deg);
}

@keyframes drift {
  0% {
    transform: translateX(0%) translateY(0%) scale(1);
  }

  95% {
    transform: translateX(-1300%) translateY(-310%) scale(0.5);
  }

  100% {
    transform: translateX(-1300%) translateY(-310%) scale(0.5);
  }
}

@keyframes drift2 {
  0% {
    transform: translateX(0%) translateY(0%) scale(1);
    opacity: 0.75;
  }

  95% {
    transform: translateX(1500%) translateY(-320%) scale(0.5);
    opacity: 0
  }

  100% {
    transform: translateX(1500%) translateY(-320%) scale(0.5);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0) rotate(-15deg);
    opacity: 0;
  }

  8% {
    transform: scale(1.1) rotate(-15deg);
    opacity: 1;
  }

  10% {
    transform: scale(1)rotate(-15deg);
    opacity: 1;
  }

  98% {
    transform: scale(1)rotate(-15deg);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1) rotate(-15deg);
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(0) rotate(10deg);
    opacity: 0;
  }

  8% {
    transform: scale(1.1) rotate(10deg);
    opacity: 1;
  }

  10% {
    transform: scale(1) rotate(10deg);
    opacity: 1;
  }

  95% {
    transform: scale(1)rotate(10deg);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1) rotate(10deg);
  }
}

p.car-leasing-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  padding: 0;
  margin-bottom: 20px;
  max-width: 992px;
  text-shadow: none;
}

p.car-leasing-description.expanded {
  -webkit-line-clamp: unset;
  line-clamp: unset;
}

.used-title {
  position: relative;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  z-index: 3;
  margin: 0 0 0.4em;
  letter-spacing: -0.025rem;
  font-size: 28px;
  padding-top: 36px;
  color: #151e1e;
}

.used-cars .used-content p {
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 20px;
  padding: 0;
  color: #151e1e;
}

/* media queries */
@media (max-width: 359.98px) {
  .hero-wrapper.hero-rating.has-excerpt .image-wrapper {
    min-height: 600px;
  }

  .hero-wrapper .image-wrapper img {
    object-position: 50% 100%;
  }

  .hero-wrapper.m360-cb .image-wrapper img {
    object-position: 50% 100%;
  }

  .hero-wrapper.m360-rb .image-wrapper img {
    object-position: 100% 100%;
  }

  .hero-wrapper.m360-rc .image-wrapper img {
    object-position: 100% 50%;
  }

  .paragraph .hero-overlay {
    background: rgba(59, 33, 86, 0.4);
  }

  .paragraph.no-mob-overlay .hero-overlay {
    background: none;
  }

  .hero-wrapper.paragraph .hero-text p {
    font-size: 15px;
  }

  .hero-wrapper.paragraph.textsmall .hero-text p {
    font-size: 13px;
  }

  hero-wrapper.paragraph.textsmall.promo .hero-text p {
    font-size: 13px;
  }

  .hero-wrapper.paragraph.textmedium .hero-text p {
    font-size: 14px;
  }

  hero-wrapper.paragraph.textmedium.promo .hero-text p {
    font-size: 14px;
  }

  .long-cta .hero-link {
    font-size: 14px;
  }
}

@media (min-width: 360px) and (max-width: 413.98px) {
  .hero-wrapper.hero-rating.has-excerpt .image-wrapper {
    min-height: 580px;
  }
}

@media (min-width: 360px) and (max-width: 479.98px) {
  .hero-wrapper .image-wrapper img {
    object-position: 50% 50%;
  }

  .hero-wrapper.m480-rb .image-wrapper img {
    object-position: 100% 100%;
  }

  .hero-wrapper.m480-cb .image-wrapper img {
    object-position: 50% 100%;
  }

  .hero-wrapper.m480-rc .image-wrapper img {
    object-position: 100% 50%;
  }
}

@media (max-width: 479.98px) {
  .hero-wrapper.home .image-wrapper {
    height: 300px;
  }

  .hero-wrapper.paragraph .image-wrapper {
    height: 400px;
  }

  .hero-wrapper.compact .image-wrapper {
    height: 300px;
  }

  .hero-wrapper.compact .image-wrapper img {
    height: 300px;
  }

  .hero-lease-deals .hero-title {
    font-size: 22px;
  }

  .icon-balloon {
    bottom: 50%;
    width: 40px;
  }

  .icon-balloon2 {
    width: 25px;
  }

  .icon-heart {
    width: 40px;
    top: 30%;
  }

  .icon-heart2 {
    width: 25px;
    top: 7%;
  }
}

@media (min-width: 415px) and (max-width: 479.98px) {
  .hero-wrapper.hero-rating.has-excerpt .image-wrapper {
    min-height: 530px;
  }
}

@media (max-width: 599.98px) {
  .hero-wrapper .image-wrapper {
    height: 360px;
  }

  .hero-wrapper.hero-lease-deals.hero-paragraph .image-wrapper {
    height: 360px;
  }

  .hero-wrapper.hero-lease-deals:not(.hero-paragraph) .image-wrapper {
    height: 240px;
  }

  .hero-wrapper.hero-rating:not(.has-excerpt) .image-wrapper {
    height: 300px;
    min-height: 300px;
  }

  .hero-wrapper.hero-rating:not(.has-excerpt).has-rating .image-wrapper {
    min-height: 360px;
    height: 360px;
  }

  .mob-overlay .hero-overlay {
    background: rgba(59, 33, 86, 0.2);
  }

  .center.hero-lease-deals .hero-text-content {
    justify-content: center;
    padding-bottom: 0;
  }

  .textsmall .hero-title {
    font-size: 24px;
  }

  .textsmall .hero-text,
  .textsmall .hero-text p {
    font-size: 14px;
  }

  .hero-vehicle-image-container {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    transform: none;
    top: auto;
    bottom: 20px;
    margin: 0 auto;
  }

  .hero-wrapper.home .cta.bottom {
    position: absolute;
    bottom: 40px;
  }

  .hero-wrapper.mob-large .image-wrapper {
    height: 440px;
  }
}

@media (min-width: 360px) and (max-width: 599.98px) {
  .hero-wrapper.paragraph.textsmall.promo .hero-text p {
    font-size: 13px;
  }
}

@media (min-width: 360px) and (max-width: 599.98px) {
  .hero-wrapper.paragraph.textmedium.promo .hero-text p {
    font-size: 14px;
  }
}

@media (max-width: 959.98px) {
  .hero-text p.size2 span {
    display: block;
  }
}

@media (min-width: 480px) {
  .mobsmall {
    display: block;
  }
}

@media (min-width: 480px) and (max-width: 599.98px) {
  .hero-wrapper.home .image-wrapper {
    height: 360px;
  }

  .hero-wrapper.compact .image-wrapper {
    height: 240px;
  }

  .hero-wrapper.compact .image-wrapper img {
    height: 240px;
  }

  .hero-wrapper.hero-rating.has-excerpt .image-wrapper {
    min-height: 510px;
  }

  .hero-wrapper .image-wrapper img {
    object-position: 100% 100%;
  }

  .hero-expander-wrapper,
  .hero-rating-wrapper {
    padding: 20px 30px;
  }

  .hero-text-content {
    padding: 20px 30px;
  }

  .hero-lease-deals .hero-title {
    font-size: 24px;
  }

  .icon-balloon {
    bottom: 50%;
    width: 40px;
  }

  .icon-balloon2 {
    width: 25px;
  }

  .icon-heart {
    width: 50px;
    top: 36%;
    left: 10%;
  }

  .icon-heart2 {
    width: 25px;
    top: 14%;
    right: 15%;
  }

  .hero-wrapper.m600-lc .image-wrapper img {
    object-position: 0% 50%;
  }

  .hero-wrapper.m600-cb .image-wrapper img {
    object-position: 50% 100%;
  }

  .hero-wrapper.m600-rc .image-wrapper img {
    object-position: 100% 50%;
  }

  .hero-wrapper.m600-rb .image-wrapper img {
    object-position: 100% 50%;
  }
}

@media (max-width: 767.98px) {
  .dream-it .hero-title {
    text-align: center;
  }

  .dream-it-hero .hero-title,
  .dream-it-hero .hero-text {
    text-align: center;
    padding-left: 3%;
    padding-right: 3%;
  }

  .used-content {
    text-align: left;
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media (min-width: 600px) {
  .hero-wrapper.hero-rating {
    overflow: hidden;
    padding-bottom: 0;
  }

  .hero-wrapper .image-wrapper {
    height: 440px;
  }

  .hero-wrapper.compact .image-wrapper {
    height: 360px;
  }

  .hero-wrapper.hero-lease-deals.hero-paragraph .image-wrapper {
    height: 440px;
  }

  .hero-wrapper .image-wrapper img {
    object-position: 100% 50%;
  }

  .hero-overlay {
    background: rgba(59, 33, 86, 0.3);
    width: 50%;
    height: 100%;
    position: relative;
  }

  .hero-overlay:after {
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 100%;
    content: "";
    display: inline-block;
    border-left: 100px solid rgba(59, 33, 86, 0.3);
    border-bottom: 440px solid transparent;
  }

  .hero-rating .hero-overlay {
    width: 100%;
  }

  .hero-expander-wrapper {
    max-width: 75%;
    padding: 0 3% 100px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hero-rating-wrapper {
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 0 3% 70px calc(3% + 280px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .top-level .hero-expander-wrapper {
    padding: 0 3%;
    max-width: 55%;
  }

  .hero-content ul.breadcrumbs {
    display: block;
  }

  .hero-content .hero-rating-wrapper ul.breadcrumbs,
  .hero-content .hero-expander-wrapper ul.breadcrumbs {
    padding-left: 0 !important;
  }

  .hero-text-content {
    padding: 0 0 0 2%;
  }

  .used-cars .hero-text-content {
    padding: 4% 0 0 4%;
  }

  .center .hero-text-content {
    justify-content: center;
  }

  .hero-lease-deals .hero-text-content {
    padding-bottom: 60px;
    width: 60%;
  }

  .hero-link {
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .hero-vehicle-image-container {
    z-index: 1;
    position: absolute;
    left: 3%;
    top: calc(50% - 40px);
    transform: translateY(-50%);
  }

  .hero-wrapper {
    overflow: hidden;
    background: var(--primary);
    padding-bottom: 0;
  }

  .mobsmall {
    font-size: 16px !important;
  }
}

@media (min-width: 600px) and (max-width: 767.98px) {
  .hero-wrapper.hero-rating.has-excerpt .image-wrapper {
    min-height: 570px;
  }

  .hero-overlay {
    width: 52%;
  }

  .hero-rating-wrapper {
    padding: 0 3% 70px calc(3% + 220px);
  }

  .hero-text-content {
    width: 52%;
  }

  .hero-rating .hero-text-content {
    width: calc(100% - 300px);
    max-width: 768px;
    left: 260px;
    padding-left: 0;
  }

  .textsmall .hero-text,
  .textsmall .hero-text p {
    font-size: 15px;
  }

  .hero-vehicle-image {
    width: 180px;
    height: 180px;
  }

  .hero-vehicle-image:before {
    top: -8px;
    left: 2px;
    transform: scale(0.975);
    border: 4px solid var(--tertiary);
  }

  .hero-vehicle-image img {
    width: 180px;
  }

  .icon-balloon {
    bottom: 55%;
    width: 50px;
  }

  .icon-balloon2 {
    width: 25px;
  }

  .icon-heart {
    width: 50px;
    top: 36%;
    left: 20%;
  }

  .icon-heart2 {
    width: 35px;
    top: 11%;
    right: 15%;
  }

  .hero-wrapper.m768-cb .image-wrapper img {
    object-position: 50% 100%;
  }
}

@media (min-width: 600px) and (max-width: 959.98px) {
  .hero-title {
    font-size: calc(23px + 1.4vw);
  }

  .used-title {
    font-size: 36px;
  }

  .used-cars .used-content p {
    font-size: 24px;
  }

  .hero-lease-deals .hero-title {
    font-size: calc(16px + .7vw);
  }

  .hero-rating .hero-title {
    font-size: calc(24px + .7vw);
  }

  .textsmall .hero-title {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .hero-wrapper.hero-rating .image-wrapper {
    min-height: 440px;
  }

  .hero-text-content {
    width: 50%;
  }

  .home .hero-text-content {
    padding-bottom: 120px;
  }

  .hero-rating .hero-text-content {
    width: calc(100% - 360px);
    max-width: 768px;
    left: 320px;
    padding-left: 0;
  }

  .hero-lease-deals .hero-text-content {
    width: 54%;
  }

  .hero-vehicle-image::before {
    left: 3px;
  }

  .dream-it .hero-text-content,
  .dream-it-hero .hero-text-content {
    padding-right: 6%;
    width: auto;
    max-width: 60%;
  }

  .used-content {
    padding-left: 6%;
    width: auto;
    max-width: 60%;
  }
}

@media (min-width: 810px) {
  .dream-it .title-logo {
    display: block;
  }
}

@media (min-width: 960px) {
  .dream-it .hero-text-content,
  .dream-it-hero .hero-text-content {
    padding-right: 8%;
  }

  .hero-title {
    font-size: calc(24px + 1.2vw);
  }

  .hero-lease-deals .hero-title {
    font-size: calc(16px + .7vw);
  }

  .hero-rating .hero-title {
    font-size: calc(24px + .7vw);
  }

  .left-35 .hero-text-content {
    width: 35%;
  }

  .used-title {
    font-size: 42px;
  }


  .used-cars .used-content p {
    font-size: 28px;
  }
}

@media (min-width: 768px) and (max-width: 959.98px) {
  .dream-it .hero-title {
    font-size: 48px;
    padding-top: 36px;
  }

  .dream-it-hero .hero-title {
    padding-top: 36px;
  }

  .icon-balloon {
    bottom: 50%;
    width: 50px;
  }

  .icon-balloon2 {
    width: 25px;
  }

  .icon-heart {
    width: 50px;
    top: 25%;
    left: 10%;
  }

  .icon-heart2 {
    width: 30px;
    top: 5%;
    right: 3%;
  }
}

@media (min-width: 768px) and (max-width: 1365.98px) {
  .hero-wrapper.hero-rating.has-excerpt .image-wrapper {
    min-height: 460px;
  }
}

@media (min-width: 960px) and (max-width: 1365.98px) {
  .dream-it .hero-title {
    font-size: 60px;
    padding-top: 36px;
  }

  .dream-it-hero .hero-title {
    padding-top: 36px;
  }

  .icon-balloon {
    bottom: 40%;
    width: 65px;
  }

  .icon-heart {
    width: 60px;
    top: 28%;
    left: 10%;
  }

  .icon-heart2 {
    width: 35px;
    top: 14%;
    right: 2.5%;
  }
}

@media (min-width: 1366px) {
  .dream-it .hero-title {
    font-size: 72px;
    padding-top: 36px;
  }

  .dream-it .hero-title {
    padding-top: 36px;
  }

  .dream-it .hero-text-content {
    text-align: center;
  }

  .used-title {
    /*  font-size: calc(24px + 1.4vw);*/
    font-size: 54px;
  }

  .used-cars .used-content p {
    /*  font-size: calc(11px + 1.4vw);*/
    font-size: 32px;
  }
}

@media (max-width: 639.98px) {
  .nissan .hero-link {
    letter-spacing: -0.8px;
  }
}
