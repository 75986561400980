/* offers-by-email */
.saved-success {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  font-size: 16px;
}

.saved-success .saved-search-header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}

.saved-success .saved-search-header .title {
  font-size: 20px !important;
  font-weight: 600;
  flex: 0 1 auto;
  padding: 10px 0;
  vertical-align: top;
}

.saved-success .icon::before {
  font: 24px rgd-icons;
  margin-right: 10px;
  text-align: center;
  width: 30px;
  float: left;
}

.saved-success .saved-search-header .icon {
  flex: 0 0 30px;
  max-width: 30px;
  font-size: 28px;
  padding-top: 5px;
  height: 35px;
  line-height: 35px;
  overflow: hidden;
}

.saved-success .saved-search-success .icon.login::before {
  content: "\f007";
}

.saved-success .saved-search-header .close {
  text-align: center;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
}

.saved-success .saved-search-header .close:after {
  content: "\f00d";
  font: 24px rgd-icons;
  height: 30px;
  line-height: 30px;
  color: var(--middark-grey);
}

.saved-success .saved-search-success {
  width: 100%;
  background: #fff;
  display: block;
  position: relative;
  font-size: 16px;
  padding: 0;
}

.saved-success p {
  font-size: 16px;
}

.saved-success .saved-search-success p.title {
  font-size: 20px;
  font-weight: 600;
}

.saved-success .saved-search-footer {
  background: #fff;
  margin-left: -10px;
  margin-right: -10px;
  padding: 0 10px 10px 10px;
}

.saved-success .saved-search-footer .cta.right {
  float: right;
}

.saved-success .saved-search-footer .cta.left {
  float: left;
  border-radius: 40px;
}

.saved-success .saved-search-success .flex-panel {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
}

.saved-success .saved-search-success .flex-item {
  flex: 1 0 0;
}

.saved-success .saved-search-success .flex-item.cta {
  flex: none;
  align-self: flex-end;
  text-align: right;
}

.saved-success .saved-search-success .linktoinboxbutton {
  width: auto;
  display: inline-block !important;
  margin-left: 20px;
  margin-top: 5px;
  padding: 0;
  line-height: normal;
}

.saved-success .saved-search-success .linktoinboxbutton a {
  width: auto;
  font-size: 16px;
  padding: 16px;
}

.saved-success .saved-search-success .linktoinboxbutton a::after {
  overflow: hidden;
  height: 18px;
  line-height: 18px;
  vertical-align: top;
}

.saved-success .quickpassword {
  position: relative;
  font-size: 16px;
}

.saved-success .qp-input-wrap {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.saved-success .qp-input-wrap li {
  position: relative;
  flex: 1 0 300px;
  margin: 0 10px 10px 10px;
}

.saved-success .qp-input-wrap input {
  border: 1px solid var(--border-grey);
}

.saved-success .qp-input-wrap .acc-label {
  margin-bottom: 5px;
  width: 100%;
  display: block;
}

.saved-success .qp-input-wrap .acc-input {
  font: 16px Work Sans,Gotham,Helvetica Neue,Helvetica,Arial,sans-serif;
  width: 100%;
  border: 1px solid var(--border-grey);
  border-radius: 5px;
  position: relative;
  height: 50px;
  padding: 5px 24px 5px 16px;
  filter: none;
}

.saved-success .qp-input-wrap .show-password {
  display: block;
  color: var(--middark-grey);
  position: absolute;
  top: 50%;
  float: none;
  bottom: 0;
  right: 8px;
  margin: 0;
  background: #fff;
  width: 24px;
  height: 24px;
  line-height: 24px;
  z-index: 2;
}

.saved-success .meta {
  font-size: 14px;
  color: var(--middark-grey);
  margin-bottom: 20px;
  max-width: 600px;
}

.saved-success .meta > span {
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.saved-success .meta ul {
  display: flex;
  flex-wrap: wrap;
}

.saved-success .meta li {
  flex: 1 1 200px;
  max-width: 200px;
  margin: 5px 0;
}

.saved-success .meta li.completed {
  color: var(--tertiary);
  font-weight: 600;
}

.saved-success .meta li.completed:before {
  content: "\f00c";
  font-family: rgd-icons;
  font-style: normal;
  font-weight: 400;
  padding-right: 5px;
  line-height: normal;
  display: inline;
}

.saved-success .saved-search-footer .qp-social-login {
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  height: 32px;
  line-height: 32px;
  color: var(--middark-grey);
  text-align: right;
  float: left;
}

.saved-success .qp-success {
  display: block;
  padding: 20px;
  background: var(--midlight-grey);
  border-radius: 5px;
  margin-bottom: 15px;
}

.saved-success .qp-success.alert {
  margin-bottom: 0;
}

.saved-success .qp-success span {
  display: inline-block;
  word-wrap: break-word;
  max-width: 100%;
  vertical-align: top;
}

.saved-success .qp-success .junk {
  font-size: 12px;
}

.saved-success .qp-success .linktoinboxbutton {
  margin-left: 0;
}

/* new code */
.saved-search-panel .account-signup {
  flex-direction: column;
}

.saved-search-panel .icon-list {
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
}

.saved-search-panel .title {
  font-size: 24px;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  margin: 0 auto 10px;
  text-align: center;
}

.saved-search-panel .subtitle {
  font-size: 18px;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  margin: 0 auto 20px;
  text-align: left;
  padding: 0;
}

.saved-search-panel .center {
  text-align: center;
}

.saved-search-panel .account-signup .subtitle {
  font-size: 20px;
  margin: 0 0 16px 0;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--primary);
  font-family: Leasing, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: left;
  padding: 0;
}

.saved-search-panel .icon {
  text-align: center;
  margin-bottom: 10px;
}

.saved-search-panel .icon i {
  font-size: 48px;
}

.saved-search-panel p {
  font-size: 16px;
  color: var(--black);
}

.saved-search-panel p.small {
  color: var(--middark-grey);
  font-size: 12px;
}

.saved-search-panel .input {
  position: relative;
  margin-bottom: 20px;
}

.saved-search-panel .input input:focus {
  box-shadow: 0 0 0 1px var(--border-grey);
}

.saved-search-panel .ch-validation-icon {
  height: 24px;
  width: 24px;
  top: 12px;
}

.saved-search-panel .ch-validation-icon::after {
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: inline-block;
  top: 0;
}

.saved-search-panel .dropdown {
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  height: 48px;
  position: relative;
  border: 1px solid var(--border-grey);
}

.saved-search-panel .dropdown::after {
  position: absolute;
  content: "\f107";
  right: 8px;
  top: 0;
  font-family: rgd-icons;
  color: var(--tertiary);
  line-height: 46px;
  font-size: 20px;
}

.saved-search-panel select {
  padding-right: 20px;
  width: 100%;
  border: none;
  font: 16px "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  height: 36px;
  line-height: 36px;
  appearance: none;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  background: transparent;
}

.saved-search-panel .cta.create-account {
  margin-bottom: 20px;
  max-width: initial;
  width: 100%;
}

.saved-searrch-panel .cta.right {
  float: right;
}

.saved-search-panel .cta button {
  float: none;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 100px;
}

/*override email checkbox*/
/* The container */
.saved-search-panel .override-email {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  min-height: 25px;
  margin-bottom: 20px;
}

.saved-search-panel .override-email label {
  display: inline-block;
  padding-top: 4px;
}

/* Hide the browser's default checkbox */
.saved-search-panel .override-email input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.saved-search-panel .override-email .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid var(--border-grey);
  border-radius: 3px;
}

/* Style the checkmark/indicator */
.saved-search-panel .override-email .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--tertiary);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.saved-search-panel .override-email input:checked ~ .checkmark:after {
  display: block;
}

.saved-search-panel .validation-exists {
  font-size: 14px;
  color: var(--tertiary);
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
}

.saved-search-panel .disclaimer {
  font-size: 12px;
  color: var(--middark-grey);
}

.saved-search-panel .linktoinboxbutton {
  font-size: 16px;
  width: auto;
  display: block;
  margin: 0 auto 40px;
}

.saved-search-panel .section {
  margin-top: 40px;
}

.saved-search-panel label.error {
  font-size: 14px;
  color: var(--error-red);
  padding: 5px 0;
  display: block;
}

/* custom checkbox*/
.opt-in-marketing .custom-checkbox {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  line-height: normal;
  min-height: 40px;
}

.opt-in-marketing .custom-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.opt-in-marketing .custom-checkbox .checkmark {
  background: #fff;
  position: absolute;
  top: 4px;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 5px;
  border: 1px solid var(--primary);
}

.opt-in-marketing .custom-checkbox input:checked ~ .checkmark {
  background: var(--primary);
}

.opt-in-marketing .custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.opt-in-marketing .custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.saved-success .post-social {
  margin-bottom: 20px;
}

.saved-success .post-social p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.saved-success .post-social .social-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.saved-success .post-social .social-icons li {
  margin-right: 15px;
}

.saved-success .post-social .social-icons button {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  text-align: left;
  border-radius: 0;
  background: #fff;
}

.saved-success .post-social .social-icons i {
  width: 20px;
  height: 20px;
  display: block;
  margin: 0 auto;
}

.saved-success .post-social .social-icons .facebook {
  border: 1px solid var(--facebook);
}

.saved-success .post-social .social-icons .linkedin {
  border: 1px solid var(--linkedin);
}

.saved-success .post-social .social-icons .twitter {
  border: 1px solid var(--twitter);
}

.saved-success .post-social .social-icons .gmail {
  border: 1px solid var(--gmail);
}

.saved-success .post-social .social-icons .microsoft {
  border: 1px solid var(--microsoft);
}

.saved-success .post-social .facebook .svg {
  background: #fff url("../media/logos/facebook.svg") no-repeat center center;
  background-size: contain;
}

.saved-success .post-social .gmail .svg {
  background: #fff url("../media/logos/google-g.svg") no-repeat center center;
  background-size: contain;
}

.saved-success .post-social .linkedin .svg {
  background: #fff url("../media/logos/linkedin.svg") no-repeat center center;
  background-size: contain;
}

.saved-success .post-social .microsoft .svg {
  background: #fff url("../media/logos/microsoft2.svg") no-repeat center center;
  background-size: contain;
}

.saved-success .post-social .twitter .svg {
  background: #fff url("../media/logos/twitter.svg") no-repeat center center;
  background-size: contain;
}

.saved-success .deal-account-signup {
  margin-bottom: 20px;
  max-width: 600px;
}


.saved-success .deal-account-signup .icon-list {
  display: flex;
  flex-wrap: wrap;
}

.saved-success .deal-account-signup .icon-list > li {
  padding: 8px 0;
  vertical-align: top;
  flex: 1 1 300px;
}

.saved-success .deal-account-signup i {
  font-size: 24px;
  display: inline-block;
  width: 24px;
  height: auto;
  vertical-align: top;
  margin-right: 10px;
}

.saved-success .deal-account-signup .icon-list > li .text-block {
  display: inline-block;
  margin-bottom: 10px;
  width: calc(100% - 50px);
  font-size: 14px;
}

/* media queries */
@media (max-width: 599.98px) {
  .saved-success .saved-search-success .linktoinboxbutton,
  .saved-success .saved-search-success .button {
    margin-left: 0;
    text-align: center;
    width: 100%;
  }

  .saved-success .saved-search-success .flex-item.cta {
    width: 100%;
    flex: 0 1 100%;
    max-width: 100%;
  }

  .saved-success .saved-search-footer .cta {
    width: 100%;
  }

  .saved-success .saved-search-panel .cta {
    order: 4;
  }

  .saved-success .saved-search-footer .qp-social-login {
    display: inline-block;
    float: none;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 479.98px) {
  .saved-success .saved-search-footer .qp-social-login {
    width: 100%;
  }
}

@media (min-width: 480px) {
  .saved-success .post-social .social-icons {
    justify-content: normal;
  }
}
