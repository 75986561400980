/* value rating gauge */
.value-rating-gauge-wrap {
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.value-rating-gauge-wrap.size1 {
  width: 48px;
  height: 40px;
}

.value-rating-gauge-wrap.size2 {
  width: 60px;
  height: 50px;
}

#groupeddeals .value-rating-gauge-wrap.size2,
.deal-card .value-rating-gauge-wrap.size2 {
  min-width: 60px;
  height: 50px;
  margin-left: 20px;
}

.value-rating-gauge-wrap.size3 {
  width: 120px;
  height: 100px;
}

.value-rating-gauge-wrap.size3.legend {
  width: 120px;
  height: 100px;
  margin-bottom: 20px;
}

.value-rating-gauge-wrap.size4 {
  width: 84px;
  height: 70px;
}

.value-rating-gauge-wrap .gauge {
  background: url("../media/ui/value-rating-sprite.svg") no-repeat 0 0/100% 1100%;
  width: 100%;
  height: 100%;
}

.value-rating-gauge-wrap[data-rating-slice="0"] .gauge {
  background-position: 0 0;
}

.value-rating-gauge-wrap[data-rating-slice="1"] .gauge {
  background-position: 0 10%;
}

.value-rating-gauge-wrap[data-rating-slice="2"] .gauge {
  background-position: 0 20%;
}

.value-rating-gauge-wrap[data-rating-slice="3"] .gauge {
  background-position: 0 30%;
}

.value-rating-gauge-wrap[data-rating-slice="4"] .gauge {
  background-position: 0 40%;
}

.value-rating-gauge-wrap[data-rating-slice="5"] .gauge {
  background-position: 0 50%;
}

.value-rating-gauge-wrap[data-rating-slice="6"] .gauge {
  background-position: 0 60%;
}

.value-rating-gauge-wrap[data-rating-slice="7"] .gauge {
  background-position: 0 70%;
}

.value-rating-gauge-wrap[data-rating-slice="8"] .gauge {
  background-position: 0 80%;
}

.value-rating-gauge-wrap[data-rating-slice="9"] .gauge {
  background-position: 0 90%;
}

.value-rating-gauge-wrap[data-rating-slice="10"] .gauge {
  background-position: 0 100%;
}

.value-rating-gauge-wrap .gauge-number {
  font: normal 36px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 20%;
  color: var(--black);
}

.value-rating-gauge-wrap.size1 .gauge-number {
  font-size: 18px;
}

.value-rating-gauge-wrap.size2 .gauge-number {
  font-size: 24px;
}

.value-rating-gauge-wrap.size3 .gauge-number {
  font-size: 48px;
}

.value-rating-gauge-wrap.size4 .gauge-number {
  font-size: 28px;
}

.value-rating-gauge-wrap .gauge-number > span {
  font-size: 0.75em;
}

.value-rating-gauge-wrap .gauge-legend {
  background: url("../media/ui/value-rating-legend.svg") no-repeat 0 0/100% 100%;
  width: 120px;
  height: 13px;
  display: block;
  position: absolute;
  bottom: 0;
}

.modal-leasing-value {
  max-width: 500px;
  background: #fff;
  margin: 0 auto;
  border-radius: 20px;
  text-align: center;
  padding: 40px 20px;
  position: relative;
}

.modal-leasing-value .title {
  font: 24px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  max-width: 300px;
  margin: 0 auto 20px;
}

.modal-leasing-value p {
  max-width: 300px;
  margin: 0 auto;
  font-size: 16px;
  text-align: left;
}

.modal-leasing-value .value-header {
  font: 600 18px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  text-align: center;
}

.vfm-close {
  font: normal 28px/44px Arial,Baskerville,monospace;
  position: absolute;
  right: 10px;
  top: 0;
  width: 44px;
  height: 44px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  border: 0;
  display: block;
  padding: 0;
  z-index: 31046;
  color: var(--primary);
  transition: none;
}

.vfm-close:hover {
  font-size: 32px;
  background: none;
}
