.deal-card {
  position: relative;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px;
  min-height: 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ns-carousel .deal-card {
  position: relative;
  flex: 1 0 auto;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.deal-card .deal-header {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px;
}

.deal-card .deal-header .image-wrap {
  max-width: 360px;
  margin: 0 auto;
  position: relative;
}

.deal-card .image-disclaimer {
  text-align: center;
  font-size: 10px;
  width: 100%;
  color: var(--middark-grey);
  z-index: 1;
  position: absolute;
  bottom: 12px;
}

.deal-card .deal-header a {
  cursor: pointer;
}

.deal-card .deal-header img {
  width: 100%;
  height: auto;
}

.edge .deal-card .deal-header img {
  pointer-events: none;
}

.deal-card .deal-body {
  background: #fff;
  padding: 16px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.deal-card .deal-vehicle {
  font: 600 24px Leasing, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  flex: 1 0 auto;
  margin-bottom: 0;
}

.deal-card .deal-body .row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.deal-card .deal-body .row.compact {
  padding: 10px 0 0;
}

.deal-card .deal-body .row .value {
  color: var(--tertiary);
  font-weight: bold;
  white-space: nowrap;
  padding-left: 20px;
  flex: 0 0 auto;
}

.deal-card .deal-body .value-rating-wrap .value {
  border-radius: 50%;
  background: var(--tertiary);
  color: #fff;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
  margin-left: 20px;
}

.deal-card .deal-body .value-rating-wrap {
  align-items: center;
}

.deal-card .cta {
  display: block;
  background: var(--secondary);
  color: #fff;
  text-align: center;
  font-weight: 600;
  padding: 16px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
}

.deal-card .cta:hover,
.deal-card .cta:focus {
  background: var(--primary);
}

.deal-card .deal-footer {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.deal-carousel .deal-card {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: transform .2s;
  flex: none;
  max-width: 100%;
  padding: 0;
  margin: 10px 0;
}

.deal-carousel .deal-card:hover {
  transform: scale(1.04);
}

.deal-carousel .deal-card:hover .cta {
  background: var(--primary);
}

.deal-carousel .deal-card a {
  color: inherit;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.deal-carousel .deal-card a:hover,
.deal-carousel .deal-card a:focus {
  text-decoration: none;
  color: inherit;
}

.deal-carousel .deal-card .deal-header img {
  max-width: 300px;
  margin: 0 auto;
}

.edge .deal-carousel .deal-card .deal-header img {
  pointer-events: none;
}

@media (max-width: 479.98px) {
  .deal-card .deal-body {
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  .deal-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 599.98px) {
  .deal-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
