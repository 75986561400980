/* leasing-icons.css */
@font-face {
  font-family: "leasing-icons";
  src: url("../fonts/leasing-icons/leasing-icons.woff2") format("woff2"), url("../fonts/leasing-icons/leasing-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ldc {
  font-family: "leasing-icons" !important;
  width: 1em;
  height: 1em;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  text-rendering: auto;
  position: relative;
  color: var(--primary);
}

.ldc:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 1;
}

.ldc:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--mid-grey);
  display: block;
  z-index: 0;
}

.ldc.lightgrey {
  color: var(--light-grey);
}

.ldc.white {
  color: #fff;
  background: transparent;
}

.ldc.no-bg:after {
  content: "";
}

.ldc.orange-bg:after {
  color: var(--secondary);
}

.ldc.teal-bg:after {
  color: var(--tertiary);
}

.ldc-leasing-12-60-circle:before {
  content: "\e902";
}

.ldc-leasing-12-60-circle:after {
  content: "\e903";
}

.ldc-leasing-address:before {
  content: "\e934";
}

.ldc-leasing-address:after {
  content: "\e935";
}

.ldc-leasing-address-3y:before {
  content: "\e936";
}

.ldc-leasing-address-3y:after {
  content: "\e937";
}

.ldc-leasing-applications:before {
  content: "\e94c";
}

.ldc-leasing-applications:after {
  content: "\e94d";
}

.ldc-leasing-bank:before {
  content: "\e940";
}

.ldc-leasing-bank:after {
  content: "\e941";
}

.ldc-leasing-book:before {
  content: "\e916";
}

.ldc-leasing-book:after {
  content: "\e917";
}

.ldc-leasing-boot-capacity:before {
  content: "\e970";
}

.ldc-leasing-boot-capacity:after {
  content: "\e971";
}

.ldc-leasing-bulb:before {
  content: "\e918";
}

.ldc-leasing-bulb:after {
  content: "\e919";
}

.ldc-leasing-bulb-large:before {
  content: "\e952";
}

.ldc-leasing-bulb-large:after {
  content: "\e953";
}

.ldc-leasing-car:before {
  content: "\e91a";
}

.ldc-leasing-car:after {
  content: "\e91b";
}

.ldc-leasing-car-circle:before {
  content: "\e906";
}

.ldc-leasing-car-circle:after {
  content: "\e907";
}

.ldc-leasing-car-large:before {
  content: "\e954";
}

.ldc-leasing-car-large:after {
  content: "\e955";
}

.ldc-leasing-car-search:before {
  content: "\e91c";
}

.ldc-leasing-car-search:after {
  content: "\e91d";
}

.ldc-leasing-car-search-large:before {
  content: "\e956";
}

.ldc-leasing-car-search-large:after {
  content: "\e957";
}

.ldc-leasing-car-seat:before {
  content: "\e91e";
}

.ldc-leasing-car-seat:after {
  content: "\e91f";
}

.ldc-leasing-car-seat-large:before {
  content: "\e958";
}

.ldc-leasing-car-seat-large:after {
  content: "\e959";
}

.ldc-leasing-car-side:before {
  content: "\e920";
}

.ldc-leasing-car-side:after {
  content: "\e921";
}

.ldc-leasing-car-side-large:before {
  content: "\e95a";
}

.ldc-leasing-car-side-large:after {
  content: "\e95b";
}

.ldc-leasing-car-subscriptions:before {
  content: "\e994";
}

.ldc-leasing-car-subscriptions:after {
  content: "\e995";
}

.ldc-leasing-chat-large:before {
  content: "\e96e";
}

.ldc-leasing-chat-large:after {
  content: "\e96f";
}

.ldc-leasing-co2:before {
  content: "\e972";
}

.ldc-leasing-co2:after {
  content: "\e976";
}

.ldc-leasing-company:before {
  content: "\e950";
}

.ldc-leasing-company:after {
  content: "\e951";
}

.ldc-leasing-drivingeu:before {
  content: "\e948";
}

.ldc-leasing-drivingeu:after {
  content: "\e949";
}

.ldc-leasing-drivingint:before {
  content: "\e94a";
}

.ldc-leasing-drivingint:after {
  content: "\e94b";
}

.ldc-leasing-drivingl:before {
  content: "\e946";
}

.ldc-leasing-drivingl:after {
  content: "\e947";
}

.ldc-leasing-drivinguk:before {
  content: "\e944";
}

.ldc-leasing-drivinguk:after {
  content: "\e945";
}

.ldc-leasing-electric-range:before {
  content: "\e977";
}

.ldc-leasing-electric-range:after {
  content: "\e978";
}

.ldc-leasing-employment:before {
  content: "\e938";
}

.ldc-leasing-employment-3y:before {
  content: "\e93a";
}

.ldc-leasing-employment-3y:after {
  content: "\e93b";
}

.ldc-leasing-employment:after {
  content: "\e939";
}

.ldc-leasing-finance-details:before {
  content: "\e93c";
}

.ldc-leasing-finance-details:after {
  content: "\e93d";
}

.ldc-leasing-finance-income:before {
  content: "\e93e";
}

.ldc-leasing-finance-income:after {
  content: "\e93f";
}

.ldc-leasing-fuel-circle-diesel:before {
  content: "\e99b";
}

.ldc-leasing-fuel-circle-diesel:after {
  content: "\e99c";
}

.ldc-leasing-fuel-circle-electric:before {
  content: "\e99f";
}

.ldc-leasing-fuel-circle-electric:after {
  content: "\e9a1";
}

.ldc-leasing-fuel-circle-hybrid:before {
  content: "\e9a4";
}

.ldc-leasing-fuel-circle-hybrid:after {
  content: "\e9a5";
}

.ldc-leasing-fuel-circle-hydrogen:before {
  content: "\e99d";
}

.ldc-leasing-fuel-circle-hydrogen:after {
  content: "\e99e";
}

.ldc-leasing-fuel-circle-plugin-hybrid:before {
  content: "\e9a2";
}

.ldc-leasing-fuel-circle-plugin-hybrid:after {
  content: "\e9a3";
}

.ldc-leasing-fuel-circle-petrol:before {
  content: "\e999";
}

.ldc-leasing-fuel-circle-petrol:after {
  content: "\e99a";
}

.ldc-leasing-fuel-diesel:before {
  content: "\e979";
}

.ldc-leasing-fuel-diesel:after {
  content: "\e97a";
}

.ldc-leasing-fuel-electric:before {
  content: "\e97b";
}

.ldc-leasing-fuel-electric:after {
  content: "\e97c";
}

.ldc-leasing-fuel-hybrid:before {
  content: "\e97d";
}

.ldc-leasing-fuel-hybrid:after {
  content: "\e97e";
}

.ldc-leasing-fuel-hydrogen:before,
.ldc-leasing-fuel-hydrogen-fuel-cell:before {
  content: "\e97f";
}

.ldc-leasing-fuel-hydrogen:after,
.ldc-leasing-fuel-hydrogen-fuel-cell:after {
  content: "\e980";
}

.ldc-leasing-fuel-petrol:before {
  content: "\e981";
}

.ldc-leasing-fuel-petrol:after {
  content: "\e982";
}

.ldc-leasing-fuel-plugin-hybrid:before {
  content: "\e983";
}

.ldc-leasing-fuel-plugin-hybrid:after {
  content: "\e984";
}

.ldc-leasing-gap-insurance:before {
  content: "\e996";
}

.ldc-leasing-gap-insurance:after {
  content: "\e997";
}

.ldc-leasing-heart:before {
  content: "\e922";
}

.ldc-leasing-heart:after {
  content: "\e923";
}

.ldc-leasing-heart-large:before {
  content: "\e95c";
}

.ldc-leasing-heart-large:after {
  content: "\e95d";
}

.ldc-leasing-home-evcharger:before {
  content: "\e990";
}

.ldc-leasing-home-evcharger:after {
  content: "\e991";
}

.ldc-leasing-initial-circle:before {
  content: "\e904";
}

.ldc-leasing-initial-circle:after {
  content: "\e905";
}

.ldc-leasing-less-200:before {
  content: "\e973";
}

.ldc-leasing-less-200:after {
  content: "\e974";
}

.ldc-leasing-less-300:before {
  content: "\e975";
}

.ldc-leasing-less-300:after {
  content: "\e98b";
}

.ldc-leasing-less-400:before {
  content: "\e98c";
}

.ldc-leasing-less-400:after {
  content: "\e98d";
}

.ldc-leasing-over-400:before {
  content: "\e98e";
}

.ldc-leasing-over-400:after {
  content: "\e98f";
}

.ldc-leasing-low-cost:before {
  content: "\e992";
}

.ldc-leasing-low-cost:after {
  content: "\e993";
}

.ldc-leasing-mail:before {
  content: "\e924";
}

.ldc-leasing-mail:after {
  content: "\e925";
}

.ldc-leasing-mail-large:before {
  content: "\e95e";
}

.ldc-leasing-mail-large:after {
  content: "\e95f";
}

.ldc-leasing-mileage-circle:before {
  content: "\e90c";
}

.ldc-leasing-mileage-circle:after {
  content: "\e90d";
}

.ldc-leasing-money-circle:before {
  content: "\e900";
}

.ldc-leasing-money-circle:after {
  content: "\e901";
}

.ldc-leasing-monthly-price-circle:before {
  content: "\e908";
}

.ldc-leasing-monthly-price-circle:after {
  content: "\e909";
}

.ldc-leasing-mpg:before {
  content: "\e985";
}

.ldc-leasing-mpg:after {
  content: "\e986";
}

.ldc-leasing-passport1:before {
  content: "\e912";
}

.ldc-leasing-passport1:after {
  content: "\e913";
}

.ldc-leasing-passport2:before {
  content: "\e914";
}

.ldc-leasing-passport2:after {
  content: "\e915";
}

.ldc-leasing-personal-details:before {
  content: "\e910";
}

.ldc-leasing-personal-details:after {
  content: "\e911";
}

.ldc-leasing-phone:before {
  content: "\e926";
}

.ldc-leasing-phone:after {
  content: "\e927";
}

.ldc-leasing-phone-large:before {
  content: "\e960";
}

.ldc-leasing-phone-large:after {
  content: "\e961";
}

.ldc-leasing-play:before {
  content: "\e928";
}

.ldc-leasing-play:after {
  content: "\e929";
}

.ldc-leasing-play-large:before {
  content: "\e962";
}

.ldc-leasing-play-large:after {
  content: "\e963";
}

.ldc-leasing-qa:before {
  content: "\e92a";
}

.ldc-leasing-qa:after {
  content: "\e92b";
}

.ldc-leasing-qa-large:before {
  content: "\e964";
}

.ldc-leasing-qa-large:after {
  content: "\e965";
}

.ldc-leasing-question:before {
  content: "\e92c";
}

.ldc-leasing-question:after {
  content: "\e92d";
}

.ldc-leasing-question-large:before {
  content: "\e966";
}

.ldc-leasing-question-large:after {
  content: "\e967";
}

.ldc-leasing-seats:before {
  content: "\e987";
}

.ldc-leasing-seats:after {
  content: "\e988";
}

.ldc-leasing-speedometer:before {
  content: "\e998";
}

.ldc-leasing-speedometer:after {
  content: "\e9a0";
}

.ldc-leasing-statement:before {
  content: "\e942";
}

.ldc-leasing-statement:after {
  content: "\e943";
}

.ldc-leasing-swatches:before {
  content: "\e92e";
}

.ldc-leasing-swatches:after {
  content: "\e92f";
}

.ldc-leasing-swatches-large:before {
  content: "\e968";
}

.ldc-leasing-swatches-large:after {
  content: "\e969";
}

.ldc-leasing-term-circle:before {
  content: "\e90a";
}

.ldc-leasing-term-circle:after {
  content: "\e90b";
}

.ldc-leasing-thumbsup:before {
  content: "\e94e";
}

.ldc-leasing-thumbsup:after {
  content: "\e94f";
}

.ldc-leasing-tick:before {
  content: "\e930";
}

.ldc-leasing-tick:after {
  content: "\e931";
}

.ldc-leasing-tick-large:before {
  content: "\e96a";
}

.ldc-leasing-tick-large:after {
  content: "\e96b";
}

.ldc-leasing-transmission:before {
  content: "\e989";
}

.ldc-leasing-transmission:after {
  content: "\e98a";
}

.ldc-leasing-tyre:before {
  content: "\e932";
}

.ldc-leasing-tyre:after {
  content: "\e933";
}

.ldc-leasing-tyre-large:before {
  content: "\e96c";
}

.ldc-leasing-tyre-large:after {
  content: "\e96d";
}

.ldc-leasing-user-circle:before {
  content: "\e90e";
}

.ldc-leasing-user-circle:after {
  content: "\e90f";
}
