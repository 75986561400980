/* breadcrumbs.css */

ul.breadcrumbs {
  font-size: 12px;
  width: 100%;
  margin: 0 10px 10px 0;
  padding: 10px 0;
  display: block;
  border-bottom: dotted 1px var(--mid-grey);
}

ul.breadcrumbs li {
  display: inline;
  margin-bottom: 0px;
  font-weight: 600;
}

ul.breadcrumbs a {
  text-decoration: none;
  line-height: 1em;
  font-weight: normal;
}

ul.breadcrumbs a:after {
  content: "\f105";
  color: var(--primary);
  font: normal 12px rgd-icons;
  vertical-align: baseline;
  margin-left: 7px;
  margin-right: 7px;
}

.purple-block ul.breadcrumbs,
.purple-block ul.breadcrumbs a,
.purple-block ul.breadcrumbs a:after {
  color: #fff;
}

.purple-block ul.breadcrumbs {
  border: none;
}

@media (max-width: 399.98px) {
  ul.breadcrumbs {
    display: none;
  }
}
