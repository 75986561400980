/* main.css */

/* leasing.com fonts */

@font-face {
  font-family: "Leasing";
  src: url("../fonts/leasing-font/Leasing_Font_Bold.woff2") format("woff2"), url("../fonts/leasing-font/Leasing_Font_Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-017f, U+2013-204a, U+20ac;
}

@font-face {
  font-family: "Work Sans";
  src: url("../fonts/work-sans/WorkSans-SemiBold.woff2") format("woff2"), url("../fonts/work-sans/WorkSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-017f, U+2013-204a, U+20ac;
}

@font-face {
  font-family: "Work Sans";
  src: url("../fonts/work-sans/WorkSans-Regular.woff2") format("woff2"), url("../fonts/work-sans/WorkSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-017f, U+2013-204a, U+20ac;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

sup {
  color: var(--primary);
}

a[href^="tel:"] {
  color: var(--tertiary);
  text-decoration: none;
}

button,
.button {
  appearance: none;
}

i {
  font-weight: 400;
  font-style: normal;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

input[type="radio"] {
  margin-right: 5px;
  vertical-align: middle;
  height: auto;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  font-family: "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 0;
  appearance: none;
}

input:invalid {
  box-shadow: none;
}

input[type="number"] {
  text-align: left;
  appearance: none;
  border-radius: 0;
}

hr {
  border: none;
  border-top: 1px solid var(--mid-grey);
  border-bottom: 1px solid #fff;
  margin: 10px 0;
}

html {
  font-size: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

html,
body {
  min-height: 100%;
  min-height: 100vh;
  min-height: 100dvh;
}

.clear-both {
  clear: both;
}

body {
  font: normal normal 16px "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--black);
  text-align: left;
  width: 100%;
  min-width: 320px;
  background: var(--main-bg);
  position: relative;
  margin: 0 auto;
  text-size-adjust: 100%;
}

:disabled {
  background: #f4f4f4 !important;
  color: var(--black);
  cursor: not-allowed;
}

button:disabled {
  background: var(--mid-grey) !important;
  cursor: no-drop;
  color: #fff;
}

button.secondary {
  color: var(--primary);
  background-color: #ffffff;
  border: 1px solid var(--primary);
}

button.secondary:hover,
button.secondary:focus {
  color: #fff;
}

button.secondary:disabled {
  color: var(--mid-grey);
  background-color: #ffffff !important;
  border: 1px solid var(--mid-grey);
}

strong,
b {
  font-weight: 600;
  font-style: normal;
}

p {
  font-size: 14px;
  padding-bottom: 20px;
  padding-top: 0;
  line-height: 1.5em;
}

a {
  color: var(--primary);
  text-decoration: none;
}

a:hover,
a:focus {
  color: var(--secondary);
  text-decoration: underline;
}

li {
  list-style: none;
}

img {
  border: none;
  display: block;
  transform: translateZ(0) scale(0.999999);
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5em;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--primary);
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.large-title {
  text-align: center;
  font-size: 48px;
  margin-bottom: 0.25em;
}

.block-text {
  max-width: 640px;
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  margin-bottom: 1em;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

iframe {
  border: none;
  overflow: hidden;
}

#webWidget {
  z-index: 999998 !important;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.google {
  background: var(--gmail);
  color: #fff;
}

.facebook {
  background: var(--facebook);
  color: #fff;
}

.linkedin {
  background: var(--linkedin);
  color: #fff;
}

.twitter {
  background: var(--twitter);
  color: #fff;
}

.gmail {
  background: var(--gmail);
  color: #fff;
}

.wrapper {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
}

.white {
  background: #fff;
}

.content-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

#home .content-table {
  max-width: 100%;
  background: var(--main-bg);
  display: block;
}

.main-body {
  width: 100%;
}

.body-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.left-panel {
  display: table-cell;
  width: 100%;
  vertical-align: top;
  padding-right: 20px;
  position: relative;
}

#fullwidth .left-panel {
  padding-right: 0;
}

.content-table.fullwidth .left-panel {
  padding-right: 0;
}

#home .content-table .left-panel {
  max-width: 1024px;
  display: block;
  margin: 0 auto;
  padding-right: 0;
}

.content {
  clear: both;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 10px;
}

.content.top {
  padding-bottom: 0;
  margin: 0;
}

.body-table .content {
  margin-top: 0;
  padding-top: 0;
}

.top-panel {
  width: 100%;
  margin-bottom: 10px;
}

.top-content {
  width: 100%;
  padding: 10px 0;
  margin: 0;
  margin-bottom: 0;
}

.content h1 {
  font-size: 32px;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 0;
  color: var(--primary);
  font-weight: 600;
}

.content h2 {
  font-size: 24px;
}

button,
.button {
  font: 600 16px / normal "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: var(--secondary);
  border-radius: 50px;
  text-align: center;
  display: block;
  padding: 16px;
  color: #fff;
  border: 0;
  cursor: pointer;
  transition: color 0.2s, background 0.2s, font-size 0.2s;
}

button:hover,
.button:hover,
button:focus,
.button:focus {
  background: var(--primary);
}

a.button {
  display: block;
  color: #fff;
}

a.button:hover,
a.button:focus {
  text-decoration: none;
  color: #fff;
}

button.green {
  background: #00b011;
}

.button.green:hover,
.button.green:focus {
  background: #009e0f;
}

button.purple {
  background: var(--primary);
  color: #fff;
}

button.purple:hover,
button.purple:focus {
  background: var(--dark-primary);
  color: #fff;
}

button.teal {
  background: var(--tertiary);
  color: #fff;
}

button.teal:hover,
button.teal:focus {
  background: var(--dark-primary);
  color: #fff;
}


button.grey {
  background: #777;
}

.button.grey:hover,
.button.grey:focus {
  background: #555;
}

button.white,
.button.white {
  background: none;
  border: 1px solid var(--primary);
  color: var(--primary);
}

button.white:hover,
.button.white:hover,
button.white:focus,
.button.white:focus {
  background: var(--primary);
  color: #fff;
}

.button.left {
  float: left;
  margin-right: 5px;
}

.button.right {
  float: right;
  margin-left: 5px;
}

button.center,
.button.center {
  margin: 0 auto;
}

.go a:after {
  content: "\f138";
  font-family: rgd-icons;
  font-style: normal;
  font-weight: 400;
  padding-left: 5px;
}

.loading {
  position: absolute;
  right: 3px;
  top: 3px;
  height: 24px !important;
  width: 24px !important;
  border: none !important;
}

.content .textblock {
  padding: 2% 3%;
  background: #fff;
  border-radius: 15px;
}

.textblock h1 {
  font-size: 32px;
  line-height: 1.25em;
}

.textblock h2 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.25em;
}

.textblock.press h3 {
  color: var(--primary);
  font-size: 18px;
  font-weight: normal;
  width: auto;
  padding: 0.25em 0;
  margin: 0;
}

.textblock.press h4 {
  color: var(--black);
  font-size: 16px;
  font-weight: 600;
  width: auto;
  padding: 0.25em 0;
  margin: 0;
  text-align: left !important;
}

.textblock ol,
.textblock ul {
  margin-bottom: 10px;
  margin-left: 20px;
  line-height: 1.5em;
}

.textblock ol li {
  padding: 3px 0 3px 15px;
}

.press li {
  padding: 0 0 10px 15px;
  background: url("../media/right-arrow2.png") no-repeat scroll left 10px;
  font-size: 14px;
}

.press li span {
  font-weight: 600;
}

blockquote {
  font: italic 16px Georgia, serif;
  margin: 20px 0;
  padding: 20px 20px 20px 50px;
  position: relative;
  color: var(--middark-grey);
  display: block;
  quotes: none;
  background: #fff;
  border-radius: 5px;
}

blockquote:before {
  display: inline-block;
  content: "";
  font-size: 80px;
  position: absolute;
  left: 0;
  top: -15px;
  color: var(--primary);
}

blockquote cite {
  color: var(--primary);
  font-size: 14px;
  display: inline-block;
  margin-top: 5px;
}

blockquote cite:before {
  content: " - ";
}

.gcw {
  margin-bottom: 20px;
}

.gcw p a {
  color: var(--secondary);
}

.gcw-logo {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.gcw-logo img {
  width: 100%;
  height: auto;
}

.edge .gcw-logo img {
  pointer-events: none;
}

.gcw-button {
  background: var(--secondary);
  padding: 8px 16px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  margin-right: 10px;
  float: left;
  transition: color 0.2s, background 0.2s;
}

.gcw-button:hover {
  background: var(--primary);
  color: #fff;
  text-decoration: none;
}

.gcw-button i {
  font-size: 14px;
  margin-left: 5px;
  vertical-align: baseline;
}

.alert {
  clear: both;
  padding: 5px 15px;
  margin-bottom: 20px;
  border: 1px dotted transparent;
  position: relative;
  padding-left: 50px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5em;
}

.alert a {
  color: inherit;
  text-decoration: underline;
}

.alert a:hover {
  color: var(--secondary);
  text-decoration: underline;
}

.alert-danger {
  background: rgba(237, 28, 36, 0.075);
  color: var(--error-red);
  border-color: var(--error-red);
}

.alert-info {
  background: rgba(231, 237, 252, 0.55);
  color: var(--primary);
  border-color: var(--tertiary);
}

.alert-warning {
  background: rgba(246, 180, 36, 0.1);
  color: var(--black);
  border-color: #f6b424;
}

.alert-info.vans,
.alert-info.upcoming,
.alert-info.saved,
.alert-warning.saved {
  padding-left: 62px;
}

.alert-info.saved,
.alert-warning.saved {
  font-size: 14px;
  margin-bottom: 0;
  min-height: 35px;
  background: #fff;
  border: 1px solid var(--primary);
  color: var(--black);
}

.alert-info.saved a,
.alert-warning.saved a {
  display: inline-block;
}

.alert-danger:before,
.alert-info:before,
.alert-warning:before,
.alert-info.vans:before,
.alert-info.upcoming:before,
.alert-info.saved:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  vertical-align: middle;
  font: 20px rgd-icons;
  color: #fff;
  padding-top: 5px;
  text-align: center;
  width: 36px;
  margin-left: -1px;
}

.alert-danger:before {
  content: "\f06a";
  background: var(--error-red);
}

.alert-info:before {
  content: "\f05a";
  background: var(--primary);
}

.alert-warning:before {
  content: "\f071";
  background: #f6b424;
}

.alert-info.vans:before {
  content: "\e900";
  font: 24px rgd-icons;
  width: 48px;
}

.alert-info.upcoming:before {
  content: "\f017";
  font: 24px rgd-icons;
  width: 48px;
}

.alert-info.saved:before,
.alert-warning.saved:before {
  content: "\f003";
  font: 20px rgd-icons;
  width: 48px;
}

.alert-info .close {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 5px;
}

.alert-info .close:after {
  content: "\f057";
  font: 20px rgd-icons;
  margin-left: 5px;
}

.alert-noicon {
  padding: 5px 15px !important;
  font-size: 14px;
}

.alert-noicon:before {
  width: 0 !important;
  content: "" !important;
}

.admin {
  margin-bottom: 20px;
  border-radius: 5px;
  color: var(--primary);
  padding: 8px 16px;
  background: transparent;
  border: 1px solid var(--primary);
  display: inline-block;
  white-space: normal;
  word-wrap: normal;
  transition: color 0.2s, background 0.2s;
  float: none;
}

.admin:hover,
.admin.focus {
  color: #fff !important;
  background: var(--primary);
  text-decoration: none;
}

.hidden {
  display: none;
}

.right {
  float: right;
}

.left {
  float: left;
}

.nofloat {
  float: none;
}

.goback {
  display: inline-block;
  font-size: 14px;
  background: none;
  margin-left: 0 !important;
  vertical-align: top;
  padding: 2px 0;
  margin-bottom: 20px;
}

.goback:hover,
.goback:focus {
  color: var(--secondary);
  text-decoration: none;
}

.goback a {
  display: block;
  color: var(--primary);
}

.goback a:hover,
.goback a:focus {
  color: var(--secondary);
  text-decoration: none;
}

.goback i {
  padding-right: 5px;
}

.goforward {
  display: inline-block;
  font-size: 14px;
  background: none;
  margin-left: 0 !important;
  vertical-align: top;
  padding: 2px 0;
  margin-bottom: 20px;
}

.goforward a {
  display: block;
  color: var(--primary);
}

.goforward a:hover,
.goforward a:focus {
  color: var(--secondary);
  text-decoration: none;
}

.goforward i {
  padding-left: 5px;
}

.back-link {
  display: inline-block;
  color: var(--secondary);
  text-decoration: underline;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.back-link::before {
  font: normal normal 18px "rgd-icons";
  color: var(--tertiary);
  margin-right: 10px;
  content: "\f053";
  vertical-align: middle;
  display: inline-block;
}

.back-link:hover,
.back-link:focus {
  color: var(--primary);
}

.social-friend fieldset {
  border: none;
}

.social-friend .input {
  position: relative;
  margin: 20px auto;
  width: 100%;
}

.social-friend input {
  font: 16px "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 100px !important;
  padding: 10px 30px 10px 20px;
  position: relative;
  background: #fff;
  border: 1px solid var(--border-grey);
  width: 100%;
  height: 48px;
}

.social-friend .input.invalid input {
  border-color: var(--error-red);
  background: #fff;
}

.social-friend .input.invalid:after {
  content: "\f06a";
  color: var(--error-red);
  font: 14px rgd-icons;
  right: 10px;
  position: absolute;
  top: 16px;
}

.social-friend input:focus {
  background: #ffc;
}

.social-friend .cta {
  margin: 5px 0 10px;
}

.social-friend .cta button {
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  border-radius: 100px;
}

.social-friend .success {
  margin: 20px 0;
  color: var(--tertiary);
}


.search-panel label.screen {
  background: none;
  padding: 0;
  display: block;
  box-shadow: none;
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  z-index: 100;
}

.forminput {
  font: 16px "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  background: #fff;
  border: 1px solid var(--border-grey);
  width: 100%;
  height: 48px;
  border-radius: 3px;
  padding: 5px 30px 5px 10px;
  border-radius: 5px !important;
}

.formtextarea {
  padding: 10px;
  resize: vertical;
  min-height: 100px;
}

.ch-validation-icon {
  width: 20px;
  height: 40px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 10;
}

.ch-validation-icon:after {
  font: 14px rgd-icons;
  line-height: 40px;
  width: 20px;
}

.query .ch-validation-icon:after {
  content: "\f110";
  color: var(--middark-grey);
  font: 20px rgd-icons;
  position: absolute;
  animation: fa-spin 1s infinite steps(8);
  height: 20px;
  top: 10px;
  line-height: 20px;
  background: none;
  margin: auto;
  right: 0;
}

.valid .ch-validation-icon:after {
  content: "\f00c";
  color: var(--valid);
}

.invalid .ch-validation-icon:after {
  content: "\f06a";
  color: var(--error-red);
}

.timeout .ch-validation-icon:after {
  content: "\f071";
  color: var(--mid-grey);
}

.fail .ch-validation-icon:after {
  content: "\f06a";
  color: var(--error-red);
}

.timeout .forminput {
  border: 1px solid #f6b424;
}

.invalid .forminput,
.fail .forminput {
  border: 1px solid var(--error-red);
}

.forminput:focus {
  background: #fff;
  border: 1px solid var(--border-grey);
}

.ch-validation-icon:hover .ch-tooltip-msg,
.ch-validation-icon:focus .ch-tooltip-msg {
  visibility: visible;
  opacity: 1;
  transform: translateX(-12px);
}

.ch-validation-icon:hover .ch-tooltip-msg:after,
.ch-validation-icon:focus .ch-tooltip-msg:after {
  visibility: visible;
  opacity: 1;
}

.valid .ch-tooltip-msg,
.query .ch-tooltip-msg {
  display: none;
}

.ch-tooltip-msg {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
  z-index: 1000;
  padding: 10px;
  width: 260px;
  color: #fff;
  background: var(--black);
  font-size: 14px;
  line-height: 1.2;
  right: 20px;
  top: 10px;
  margin-left: 0;
  margin-top: -6px;
  text-align: left;
  border-radius: 3px;
}

.ch-tooltip-msg:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
  z-index: 1001;
  background: transparent;
  content: "";
  top: 10px;
  left: 100%;
  margin-bottom: 0;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid var(--black);
}

.linktoinboxbutton {
  font: 600 20px/50px "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 100%;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 50px;
  text-align: center;
  display: block;
  color: var(--primary);
  cursor: pointer;
  transition: color 0.2s, background-color 0.2s;
  border: 1px solid var(--primary);
}

.linktoinboxbutton:hover,
.linktoinboxbutton:focus {
  background: var(--primary);
}

.linktoinboxbutton a:after {
  font: 24px rgd-icons;
  content: "\f105";
  margin-left: 10px;
}

.linktoinboxbutton a {
  width: 100%;
  display: block;
  color: var(--primary);
  padding: 0 16px;
}

.linktoinboxbutton:hover a,
.linktoinboxbutton:focus a {
  color: #fff;
  text-decoration: none;
}

.gdpr {
  clear: both;
  width: 100%;
  font-size: 11px !important;
  color: var(--middark-grey);
  margin: 20px 0 15px 0;
  text-align: left;
  padding: 0 10px 0 20px !important;
  display: block;
  position: relative;
  line-height: 1.5em;
}

.gdpr a {
  color: var(--black);
}

.gdpr:before {
  content: "\f05a";
  font: 14px rgd-icons;
  margin-right: 5px;
  text-decoration: none;
  display: inline-block;
  padding: 0 1px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  color: var(--tertiary);
}

.small-info {
  width: 100%;
  font-size: 11px !important;
  color: var(--middark-grey);
  margin: 20px 0 15px 0;
  text-align: left;
  padding: 0 10px 0 25px !important;
  display: block;
  position: relative;
  line-height: 1.5em;
}


.small-info:before {
  content: "\f05a";
  font: 18px rgd-icons;
  margin-right: 10px;
  text-decoration: none;
  display: inline-block;
  padding: 0 1px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  color: var(--tertiary);
}

::placeholder {
  color: var(--dark-grey);
}

input[readonly]::placeholder,
textarea[readonly]::placeholder {
  color: transparent;
}

input.placeholder {
  font: 16px "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  background: #fff;
  border: none;
  width: 100%;
  height: 48px;
  border-radius: 5px;
  padding: 5px 40px 0 10px;
}

input.placeholder.large {
  font-size: 16px;
  height: 54px;
  padding: 5px 40px 0 10px;
}

input.placeholder:focus,
textarea.placeholder:focus {
  box-shadow: 0 0 0 1px var(--border-grey);
}

.invalid input.placeholder {
  border: 1px solid var(--error-red);
}

.invalid input.placeholder:focus,
.invalid textarea.placeholder:focus {
  box-shadow: 0 0 0 1px var(--error-red);
}

.show-password {
  float: right;
  margin-bottom: 5px;
  color: var(--middark-grey);
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: 5px;
}

.show-password label,
.show-password i {
  cursor: pointer;
}

.show-icon::before {
  font: normal 400 20px rgd-icons;
  content: "\f06e";
  padding-right: 5px;
  vertical-align: top;
}

.hide-icon:before {
  font: normal 400 20px rgd-icons;
  content: "\f070";
  padding-right: 5px;
  vertical-align: top;
}

.modal-tooltip {
  max-width: 500px;
  background: #fff;
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
  padding: 40px 20px;
  position: relative;
}

.modal-tooltip .title {
  font: 24px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  max-width: 300px;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.white-block {
  padding: 40px 0;
  background: #fff;
  position: relative;
}

.purple-block {
  position: relative;
  padding: 40px 0;
  background: var(--primary);
}

.black-block {
  background: #151515;
}

.plain-block {
  padding: 40px 0;
  position: relative;
}

.teal-block {
  padding: 40px 0;
  background: var(--tertiary);
  position: relative;
}

.orange-block {
  padding: 40px 0;
  background: var(--secondary);
  position: relative;
}

.white-block.center,
.purple-block.center,
.plain-block.center,
.teal-block.center,
.orange-block.center {
  text-align: center;
}

.no-padding {
  padding: 0;
}

.small-padding,
.padding20 {
  padding: 20px 0;
}

.padding40 {
  padding: 40px 0;
}

.medium-padding,
.padding60 {
  padding: 60px 0;
}

.large-padding,
.padding80 {
  padding: 80px 0;
}

.white-block.chevron:before,
.purple-block.chevron:before,
.teal-block.chevron:before,
.plain-block.chevron:before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 11;
}

.white-block.chevron:after,
.purple-block.chevron:after,
.teal-block.chevron:after,
.plain-block.chevron:after {
  font: normal 400 24px/40px rgd-icons;
  content: "\f107";
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  text-align: center;
  z-index: 12;
}

.white-block.chevron:before {
  background: #fff;
}

.purple-block.chevron:before {
  background: var(--primary);
}

.teal-block.chevron:before {
  background: var(--tertiary);
}

.plain-block.chevron:before {
  background: var(--main-bg);
}

.white-block.chevron:after,
.plain-block.chevron:after {
  color: var(--primary);
}

.purple-block.chevron:after,
.teal-block.chevron:after {
  color: #fff;
}

.purple-block h1,
.purple-block h2,
.purple-block h3,
.purple-block h4,
.purple-block p,
.teal-block h1,
.teal-block h2,
.teal-block h3,
.teal-block h4,
.teal-block p,
.orange-block h1,
.orange-block h2,
.orange-block h3,
.orange-block h4,
.orange-block p {
  color: #fff;
}

.purple-block .button:hover,
.purple-block .button:focus {
  background: var(--dark-primary);
}

.orange-block .button {
  background: var(--primary);
}

.orange-block .button:hover,
.orange-block .button:focus {
  background: var(--dark-primary);
}

.container-16-9 {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(9 / 16 * 100%);
  overflow: hidden;
  background: #fff;
  display: block;
}

.container-16-9 > :first-child {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.container-3-2 {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(2 / 3 * 100%);
  overflow: hidden;
  display: block;
  background: #fff;
}

.container-3-2.rounded20 {
  border-radius: 20px;
}

.container-3-2:not(.nozoom) img {
  transition: transform 0.2s;
}

.edge .container-3-2:not(.nozoom) img {
  pointer-events: none;
}

.container-3-2:not(.nozoom):hover img,
.container-3-2:not(.nozoom):focus img {
  transform: scale(1.05) translateZ(0);
}

.container-3-2 > :first-child {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.container-4-3 {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(3 / 4 * 100%);
  overflow: hidden;
  display: block;
  background: #fff;
}

.container-4-3:not(.nozoom) img {
  transition: transform 0.2s;
}

.edge .container-4-3:not(.nozoom) img {
  pointer-events: none;
}

.container-4-3:not(.nozoom):hover img,
.container-4-3:not(.nozoom):focus img {
  transform: scale(1.05) translateZ(0);
}

.container-4-3 > :first-child {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.image-margin10 {
  margin: 0 0 10px;
}

.image-margin20 {
  margin: 0 0 20px;
}

.strike {
  text-decoration: line-through;
}

.latest {
  width: 260px;
  margin: 0 auto 20px;
}

.latest i {
  margin-left: 10px;
}

.top-local .make {
  font: 18px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  margin-bottom: 0;
}

.top-local .model {
  font-family: "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--black);
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 10px;
}

.top-local p {
  font-size: 16px;
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
}

.flex-grid .col2 .image-wrap {
  max-width: 360px;
  margin: 0 auto;
}

.content-flex {
  display: flex;
  flex-direction: row;
}

.flex-left-panel {
  flex: 1 1 100%;
  max-width: 100%;
}

.flex-right-panel {
  flex: 0 0 300px;
  max-width: 300px;
}

.flex-left-panel .content {
  padding: 0 30px 0 0;
}

.right-panel-block {
  margin-bottom: 20px;
  padding: 20px;
  font-size: 14px;
}

.right-panel-block h3 {
  font-size: 20px;
}

.right-panel-block.purple h3 {
  color: #fff;
}

.right-panel-block .title {
  font: normal 600 20px / normal Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 20px;
}

.right-panel-block.white {
  background: #fff;
  border-radius: 15px;
}

.right-panel-block.purple {
  background: var(--primary);
  color: #fff;
  border-radius: 15px;
}

.right-panel-block .image-wrap {
  margin: -20px -20px 20px -20px;
}

.right-panel-block a {
  font-size: 14px;
  color: var(--secondary);
  text-decoration: none;
  transition: color 0.2s;
}

.right-panel-block a:hover {
  color: var(--primary);
  text-decoration: underline;
}

.right-panel-block button,
.right-panel-block .button {
  float: none;
  width: 100%;
  color: #fff;
}

.right-panel-block button:hover,
.right-panel-block .button:hover {
  color: #fff;
  text-decoration: none;
}

.right-panel-block.purple button:hover,
.right-panel-block.purple .button:hover {
  color: #fff;
  background: var(--tertiary);
  text-decoration: none;
}

.right-panel-block ul {
  margin-bottom: 20px;
}

.right-panel-block li {
  margin-bottom: 10px;
}

.right-panel-block .icon-list > li {
  min-height: 3em;
}

.right-panel-block .icon-list i {
  font-size: 32px;
  display: inline-block;
  width: 32px;
  height: auto;
  vertical-align: top;
  margin-right: 10px;
  margin-top: -8px;
}

.right-panel-block .icon-list > li > .text-block {
  display: inline-block;
  margin-bottom: 10px;
  width: calc(100% - 42px);
}

.section-icon {
  margin: 0 auto;
  font-size: 48px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  display: block;
}

/* zendesk custom launcher */
iframe#launcher,
iframe[data-product="web_widget"] {
  padding: 0 !important;
  margin: 0 !important;
  bottom: 5px !important;
  right: 5px !important;
  opacity: 0.75 !important;
  transition: none !important;
}

iframe#launcher:hover,
iframe[data-product="web_widget"]:hover {
  opacity: 1 !important;
  transition: none !important;
}

.city-image-wrap {
  max-width: 360px;
  margin: 0 auto;
  position: relative;
}

.city-image-wrap {
  width: 100%;
  height: auto;
}

.compact768 {
  max-width: 768px !important;
}

.compact992 {
  max-width: 992px !important;
}

.search-links-wrap {
  overflow-x: auto;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  border-radius: 5px;
}

.search-links-wrap:before {
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0px;
  content: "";
  background: #e9e9e9;
  background: linear-gradient(90deg, rgba(233,233,233) 0%, rgba(0,0,0,0) 100%);
  width: 10px;
  z-index: 2;
}

.search-links-wrap:after {
  position: absolute;
  top: 0;
  bottom: 10px;
  right: 0px;
  content: "";
  background: #e9e9e9;
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(233,233,233) 100%);
  width: 10px;
}


.search-links {
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: auto;
  display: flex;
  background: #e9e9e9;
  padding: 10px;
}

.search-links .title {
  font-size: 14px !important;
}

.search-links > span {
  margin: 5px;
  flex: 0 1 auto;
  vertical-align: middle;
  color: var(--primary);
  white-space: nowrap;
  position: relative;
  height: 30px;
  line-height: 28px;
  font-size: 14px;
  user-select: none;
  font-weight: 600;
}

.search-links > span a {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  user-select: none;
  transition: color 0.2s, background-color 0.2s;
  font-weight: normal;
  color: var(--primary);
}

.search-links > span a:hover,
.search-links > span a:focus {
  color: #fff;
  background: var(--primary);
  text-decoration: none;
}

.fydc .icon-list {
  display: block;
  margin: 0 auto;
  width: 240px;
}

.fydc.right-panel-block .icon-list > li > .text-block {
  font-size: 16px;
}

.fydc.right-panel-block .inner {
  max-width: 480px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fydc.right-panel-block .title {
  position: relative;
  font-size: 24px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
}

.fydc.right-panel-block .title .orange {
  color: var(--secondary);
}

.fydc.right-panel-block .title > i {
  vertical-align: middle;
  font-size: 36px;
  margin-top: 5px;
  display: block;
  flex: 1 0 auto;
}

.fydc.right-panel-block .title > i:first-of-type {
  margin-right: 10px;
  transform: rotate(-15deg);
}

.fydc.right-panel-block .title > i:last-of-type {
  margin-left: 10px;
  transform: rotate(15deg);
}

.fydc.right-panel-block a {
  font-size: 16px;
}

.fydc-banner {
  width: 100%;
}

.sf2 .fydc-banner {
  margin-bottom: 40px;
}

.fydc-banner .image-wrap {
  width: 100%;
  padding: 0.5em 0;
}

.fydc-banner .image-wrap img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 5px;
}

.edge .fydc-banner .image-wrap img {
  pointer-events: none;
}

select option {
  padding-top: 5px !important;
  padding-right: 5px !important;
  height: 24px !important;
  line-height: 24px !important;
  cursor: default;
  background-color: #fff;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
}

.chat-widget {
  position: fixed;
  bottom: 20px;
  left: 10px;
  background: var(--tertiary);
  color: #fff;
  font-size: 14px;
  border-radius: 50px;
  z-index: 100;
  padding: 15px;
  display: inline-block;
  font-weight: bold;
  border: 1px solid #fff;
  transition: background-color 0.2s;
  display: none;
}

.chat-widget:hover {
  background: var(--primary);
}

.chat-widget a {
  color: #fff;
}

.chat-widget i {
  font-size: 24px;
  vertical-align: middle;
}

.chat-widget span {
  display: none;
  margin-left: 8px;
}

.inline-video {
  width: 100%;
  max-width: 768px;
  margin: 0 auto 40px;
}

.buttonrounded {
  border-radius: 100px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button40 {
  height: 40px;
}

.button50 {
  height: 50px;
}

.button60 {
  height: 60px;
}

input[type="text"].inputrounded,
input[type="email"].inputrounded,
input[type="tel"].inputrounded,
select.inputrounded {
  border-radius: 10px !important;
}

textarea.inputrounded {
  border-radius: 10px 10px 0 10px !important;
}

.inputrounded5 {
  border-radius: 5px !important;
}

textarea.inputrounded5 {
  border-radius: 5px 5px 0 5px !important;
}

/* new izmo padding code */

.izmo .padded {
  padding: 8%;
  background-color: #fff;
}

.izmo .container-3-2 .padded {
  padding: 5.3% 8%;
}

#cookiefirst button {
  display: inline-block;
  margin: 10px;
  border-radius: 100px;
}

#cookiefirst td,
#cookiefirst td:nth-child(2),
.cf1M40 td:nth-child(2) {
  min-width: auto !important;
}

#cookiefirst th {
  white-space: normal !important;
}

/* * * * * * * * * * * * */
/* media queries */
@media (min-width: 1921px) {
  #home:before {
    background-size: 1920px;
  }
}

@media (min-width: 960px) {
  .top-local p {
    max-width: 66%;
  }
}

@media (min-width: 768px) {
  .chat-widget {
    padding: 10px 20px;
  }

  .chat-widget span {
    display: inline-block;
  }

  .chat-widget i {
    font-size: 20px;
  }
}

@media (min-width: 600px) {
  .flex-grid .col2 {
    flex-basis: 50%;
    flex-grow: 1;
    padding: 0 20px;
    max-width: 50%;
  }
}


@media (max-width: 1067.98px) {
  .wrapper {
    padding: 0 3%;
  }
}

@media (max-width: 959.98px) {
  .content-table {
    box-shadow: none;
  }

  .left-panel {
    padding-right: 0;
    display: block;
    float: none;
    width: 100%;
  }

  .dragscroll {
    cursor: grab;
  }

  .content-flex {
    flex-wrap: wrap;
  }

  .flex-left-panel {
    flex: 1 1 100%;
    margin-bottom: 40px;
  }

  .flex-left-panel .content {
    padding: 0;
  }

  .flex-right-panel {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .right-panel-block {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 959.98px) {
  .right-panel-block {
    flex: 1 1 48.5%;
    max-width: 48.5%;
    display: flex;
    flex-direction: column;
  }

  .right-panel-block p {
    flex: 1 1 auto;
  }

  .right-panel-block.full-width {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .wrapper.white {
    background: #fff;
  }

  .wrapper.mob-white {
    background: #fff;
  }

  .top-panel {
    background: var(--main-bg);
    margin-left: -3%;
    margin-right: -3%;
    padding-left: 3%;
    padding-right: 3%;
    width: calc(100% + 6%);
  }
}

@media (max-width: 767.98px) {
  html {
    overflow-y: auto;
  }

  .bell {
    font-size: 20px;
  }

  iframe#launcher,
  iframe[data-product="web_widget"] {
    transform: rotate( -90deg) translate(100%, 0%);
    transform-origin: bottom right;
  }
}

@media (max-width: 599.98px) {
  .intro-video {
    background: rgba(255, 255, 255, 0.6);
  }

  .flex-grid .col2 {
    flex-basis: 100%;
  }

  .large-title {
    font-size: 24px;
  }

  .section-icon {
    font-size: 36px;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
}

@media (max-width: 479.98px) {
  .textblock ol li {
    padding: 3px 0 3px 15px;
  }

  .social-friend ul li {
    clear: both;
    margin-bottom: 10px;
  }

  .social-friend label {
    width: 100%;
    padding: 0;
    height: auto;
    text-align: left;
  }

  .social-friend input {
    width: 100%;
    border-radius: 5px;
  }

  .content h1 {
    font-size: 24px;
  }
}
/* new media breaks code */
.wrapper-1400 {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 3%;
  position: relative;
}

.wrapper-1200 {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 3%;
  position: relative;
}

@media (min-width: 576px) {
  .wrapper-1400,
  .wrapper-1200 {
    padding: 0 16px
  }
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-device-pixel-ratio: 1.25), (-webkit-min-device-pixel-ratio: 2.0833333333333335), (min-resolution: 200dpi), (min-resolution: 1.25dppx) {
  .search-links {
    overflow-x: auto;
  }
}
