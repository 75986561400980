@font-face {
  font-family: "rgd-icons";
  src: url("../fonts/rgd-icons/rgd-icons.woff2") format("woff2"), url("../fonts/rgd-icons/rgd-icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.fa {
  font-family: "rgd-icons" !important;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: inherit;
  line-height: 1;
  text-transform: none;
  text-rendering: auto;
}

.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571429em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.85714286em;
}

.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: solid 0.08em var(--midlight-grey);
  border-radius: 0.1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
  filter: none;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* icons */
.fa-account:before {
    content: "\e946";
}

.fa-alert:before {
    content: "\e947";
}

.fa-angle-double-down:before {
    content: "\f103";
}

.fa-angle-double-left:before {
    content: "\f100";
}

.fa-angle-double-right:before {
    content: "\f101";
}

.fa-angle-double-up:before {
    content: "\f102";
}

.fa-angle-down:before {
    content: "\f107";
}

.fa-angle-left:before {
    content: "\f104";
}

.fa-angle-right:before {
    content: "\f105";
}

.fa-angle-up:before {
    content: "\f106";
}

.fa-app-complete:before {
    content: "\e919";
}

.fa-app-expired:before {
    content: "\e918";
}

.fa-app-withdrawn:before {
    content: "\e91a";
}

.fa-area-chart:before {
    content: "\f1fe";
}

.fa-arrow-circle-down:before {
    content: "\f0ab";
}

.fa-arrow-circle-left:before {
    content: "\f0a8";
}

.fa-arrow-circle-o-down:before {
    content: "\f01a";
}

.fa-arrow-circle-o-left:before {
    content: "\f190";
}

.fa-arrow-circle-o-right:before {
    content: "\f18e";
}

.fa-arrow-circle-o-up:before {
    content: "\f01b";
}

.fa-arrow-circle-right:before {
    content: "\f0a9";
}

.fa-arrow-circle-up:before {
    content: "\f0aa";
}

.fa-arrow-down:before {
    content: "\f063";
}

.fa-arrow-left:before {
    content: "\f060";
}

.fa-arrow-right:before {
    content: "\f061";
}

.fa-arrows-alt:before {
    content: "\f0b2";
}

.fa-arrow-up:before {
    content: "\f062";
}

.fa-asterisk:before {
    content: "\f069";
}

.fa-autorenew:before {
    content: "\e606";
}

.fa-ban:before {
    content: "\f05e";
}

.fa-bars:before {
    content: "\f0c9";
}

.fa-bell:before {
    content: "\f0f3";
}

.fa-bell-o:before {
    content: "\f0a2";
}

.fa-bell-slash:before {
    content: "\f1f6";
}

.fa-bell-slash-o:before {
    content: "\f1f7";
}

.fa-bodystyle-convertible:before {
    content: "\e93a";
}

.fa-bodystyle-coupe:before {
    content: "\e93b";
}

.fa-bodystyle-estate:before {
    content: "\e93c";
}

.fa-bodystyle-hardtop:before {
    content: "\e93d";
}

.fa-bodystyle-hatchback:before {
    content: "\e93e";
}

.fa-bodystyle-roadster:before {
    content: "\e93f";
}

.fa-bodystyle-saloon:before {
    content: "\e940";
}

.fa-bodystyle-softtop:before {
    content: "\e941";
}

.fa-bodystyle-stationwagon:before {
    content: "\e942";
}

.fa-bodystyle-suv:before {
    content: "\e943";
}

.fa-breakdown:before {
    content: "\e948";
}

.fa-bullseye:before {
    content: "\f140";
}

.fa-business-benefits:before {
    content: "\e949";
}

.fa-calendar:before {
    content: "\f073";
}

.fa-calendar1:before {
    content: "\e911";
}

.fa-calendar2:before {
    content: "\e912";
}

.fa-calendar3:before {
    content: "\e94a";
}

.fa-calendar-o:before {
    content: "\f133";
}

.fa-call-back:before {
    content: "\e602";
}

.fa-camera:before {
    content: "\f030";
}

.fa-cap-logo:before {
    content: "\e600";
}

.fa-caret-down:before {
    content: "\f0d7";
}

.fa-caret-left:before {
    content: "\f0d9";
}

.fa-caret-right:before {
    content: "\f0da";
}

.fa-caret-up:before {
    content: "\f0d8";
}

.fa-car-front:before {
    content: "\e937";
}

.fa-car-side:before {
    content: "\e938";
}

.fa-car-simple:before {
    content: "\e944";
}

.fa-chain:before {
    content: "\f0c1";
}

.fa-chain-broken:before {
    content: "\f127";
}

.fa-chal-car:before {
    content: "\e605";
}

.fa-chat:before {
    content: "\e94b";
}

.fa-check:before {
    content: "\f00c";
}

.fa-check-circle:before {
    content: "\f058";
}

.fa-check-circle-o:before {
    content: "\f05d";
}

.fa-check-square:before {
    content: "\f14a";
}

.fa-check-square-o:before {
    content: "\f046";
}

.fa-chevron-circle-down:before {
    content: "\f13a";
}

.fa-chevron-circle-left:before {
    content: "\f137";
}

.fa-chevron-circle-right:before {
    content: "\f138";
}

.fa-chevron-circle-up:before {
    content: "\f139";
}

.fa-chevron-down:before {
    content: "\f078";
}

.fa-chevron-left:before {
    content: "\f053";
}

.fa-chevron-right:before {
    content: "\f054";
}

.fa-chevron-up:before {
    content: "\f077";
}

.fa-circle:before {
    content: "\f111";
}

.fa-circle-o:before {
    content: "\f10c";
}

.fa-circle-thin:before {
    content: "\f1db";
}

.fa-clipboard:before {
    content: "\f0ea";
}

.fa-clock:before {
    content: "\e94c";
}

.fa-clock-o:before {
    content: "\f017";
}

.fa-close:before {
    content: "\f00d";
}

.fa-close1:before {
    content: "\e91f";
}

.fa-cog:before {
    content: "\f013";
}

.fa-coin:before {
    content: "\e913";
}

.fa-comment:before {
    content: "\f075";
}

.fa-comment-o:before {
    content: "\f0e5";
}

.fa-comments:before {
    content: "\f086";
}

.fa-comments-o:before {
    content: "\f0e6";
}

.fa-copy:before {
    content: "\f0c5";
}

.fa-cross-circle:before {
    content: "\e94d";
}

.fa-dashboard:before {
    content: "\f0e4";
}

.fa-database:before {
    content: "\f1c0";
}

.fa-desktop:before {
    content: "\f108";
}

.fa-dot-circle-o:before {
    content: "\f192";
}

.fa-edit:before {
    content: "\f044";
}

.fa-electric-flash:before {
    content: "\e94e";
}

.fa-ellipsis-h:before {
    content: "\f141";
}

.fa-ellipsis-v:before {
    content: "\f142";
}

.fa-email-withheld:before {
    content: "\e91c";
}

.fa-envelope:before {
    content: "\f0e0";
}

.fa-envelope-o:before {
    content: "\f003";
}

.fa-envelope-open:before {
    content: "\f2b6";
}

.fa-envelope-open-o:before {
    content: "\f2b7";
}

.fa-exchange:before {
    content: "\f0ec";
}

.fa-exclamation:before {
    content: "\f12a";
}

.fa-exclamation-circle:before {
    content: "\f06a";
}

.fa-exclamation-circle-o:before {
    content: "\e922";
}

.fa-exclamation-triangle:before {
    content: "\f071";
}

.fa-expand:before {
    content: "\e927";
}

.fa-external-link:before {
    content: "\f08e";
}

.fa-eye:before {
    content: "\f06e";
}

.fa-eye-slash:before {
    content: "\f070";
}

.fa-facebook:before {
    content: "\f09a";
}

.fa-facebook-f:before {
    content: "\f09a";
}

.fa-facebook-square:before {
    content: "\f082";
}

.fa-fast-delivery:before {
    content: "\e94f";
}

.fa-files-o:before {
    content: "\f0c5";
}

.fa-file-text-o:before {
    content: "\f0f6";
}

.fa-flexible-term:before {
    content: "\e950";
}

.fa-floppy-o:before {
    content: "\f0c7";
}

.fa-folder-open-o:before {
    content: "\f115";
}

.fa-fuel-circle-diesel:before {
    content: "\e931";
}

.fa-fuel-circle-electric:before {
    content: "\e933";
}

.fa-fuel-circle-hybrid:before {
    content: "\e935";
}

.fa-fuel-circle-hydrogen:before {
    content: "\e932";
}

.fa-fuel-circle-petrol:before {
    content: "\e930";
}

.fa-fuel-circle-plugin-hybrid:before {
    content: "\e934";
}

.fa-fuel-diesel:before {
    content: "\e908";
}

.fa-fuel-electric:before {
    content: "\e909";
}

.fa-fuel-hybrid:before {
    content: "\e90a";
}

.fa-fuel-petrol:before {
    content: "\e90b";
}

.fa-fuel-pump:before {
    content: "\e915";
}

.fa-fuel-pump-diesel:before {
    content: "\e92c";
}

.fa-fuel-pump-electric:before {
    content: "\e92e";
}

.fa-fuel-pump-hybrid:before {
    content: "\e92f";
}

.fa-fuel-pump-hydrogen:before {
    content: "\e92d";
}

.fa-fuel-pump-petrol:before {
    content: "\e92b";
}

.fa-gallery:before {
    content: "\e928";
}

.fa-gallery2:before {
    content: "\e92a";
}

.fa-gallery3:before {
    content: "\e951";
}

.fa-gear:before {
    content: "\f013";
}

.fa-gears-manual:before {
    content: "\e90c";
}

.fa-gears-manual-round:before {
    content: "\e914";
}

.fa-genderless:before {
    content: "\f1db";
}

.fa-globe:before {
    content: "\f0ac";
}

.fa-google-plus:before {
    content: "\f0d5";
}

.fa-google-plus-square:before {
    content: "\f0d4";
}

.fa-group:before {
    content: "\f0c0";
}

.fa-heart-o:before {
    content: "\e91e";
}

.fa-help-circle:before {
    content: "\e952";
}

.fa-hidden:before {
    content: "\e953";
}

.fa-home:before {
    content: "\f015";
}

.fa-hot:before {
    content: "\e924";
}

.fa-hotukdeals:before {
    content: "\e916";
}

.fa-info-circle:before {
    content: "\f05a";
}

.fa-info-circle2:before {
    content: "\e954";
}

.fa-info-circle-o:before {
    content: "\e923";
}

.fa-instagram:before {
    content: "\f16d";
}

.fa-interact:before {
    content: "\e907";
}

.fa-laptop:before {
    content: "\f109";
}

.fa-level-down:before {
    content: "\f149";
}

.fa-level-up:before {
    content: "\f148";
}

.fa-line-chart:before {
    content: "\f201";
}

.fa-link:before {
    content: "\f0c1";
}

.fa-linkedin:before {
    content: "\f0e1";
}

.fa-linkedin-square:before {
    content: "\f08c";
}

.fa-list-ul:before {
    content: "\f0ca";
}

.fa-lock:before {
    content: "\f023";
}

.fa-long-arrow-down:before {
    content: "\f175";
}

.fa-long-arrow-up:before {
    content: "\f176";
}

.fa-lower-price:before {
    content: "\e955";
}

.fa-low-vision:before {
    content: "\f2a8";
}

.fa-mail:before {
    content: "\e956";
}

.fa-mail-reply:before {
    content: "\f112";
}

.fa-maintained-no:before {
    content: "\e903";
}

.fa-maintained-opt:before {
    content: "\e904";
}

.fa-maintained-yes:before {
    content: "\e902";
}

.fa-mileage:before {
    content: "\e957";
}

.fa-minus-circle:before {
    content: "\f056";
}

.fa-minus-square:before {
    content: "\f146";
}

.fa-minus-square-o:before {
    content: "\f147";
}

.fa-mobile:before {
    content: "\f10b";
}

.fa-mobile-phone:before {
    content: "\f10b";
}

.fa-money:before {
    content: "\e90d";
}

.fa-movetop:before {
    content: "\e906";
}

.fa-navicon:before {
    content: "\f0c9";
}

.fa-newspaper-o:before {
    content: "\f1ea";
}

.fa-novehicles:before {
    content: "\e925";
}

.fa-padlock:before {
    content: "\e958";
}

.fa-paperclip:before {
    content: "\f0c6";
}

.fa-paper-plane:before {
    content: "\f1d8";
}

.fa-paper-plane-o:before {
    content: "\f1d9";
}

.fa-partners:before {
    content: "\e959";
}

.fa-paste:before {
    content: "\f0ea";
}

.fa-pencil-square-o:before {
    content: "\f044";
}

.fa-phone:before {
    content: "\f095";
}

.fa-phone2:before {
    content: "\e95a";
}

.fa-phone-square:before {
    content: "\f098";
}

.fa-phone-withheld:before {
    content: "\e91b";
}

.fa-plus-circle:before {
    content: "\f055";
}

.fa-plus-square:before {
    content: "\f0fe";
}

.fa-plus-square-o:before {
    content: "\f196";
}

.fa-pound-calendar:before {
    content: "\e95b";
}

.fa-pound-circle:before {
    content: "\e95c";
}

.fa-print:before {
    content: "\f02f";
}

.fa-question:before {
    content: "\f128";
}

.fa-question-circle:before {
    content: "\f059";
}

.fa-question-circle-o:before {
    content: "\f29c";
}

.fa-queue:before {
    content: "\e604";
}

.fa-quote-left:before {
    content: "\f10d";
}

.fa-quote-right:before {
    content: "\f10e";
}

.fa-range:before {
    content: "\e95d";
}

.fa-refresh:before {
    content: "\f021";
}

.fa-refresh-thin:before {
    content: "\e917";
}

.fa-remove:before {
    content: "\f00d";
}

.fa-reorder:before {
    content: "\f0c9";
}

.fa-repeat:before {
    content: "\f01e";
}

.fa-reply:before {
    content: "\f112";
}

.fa-reset:before {
    content: "\e95e";
}

.fa-road-tax:before {
    content: "\e90e";
}

.fa-rotate-left:before {
    content: "\f0e2";
}

.fa-rotate-right:before {
    content: "\f01e";
}

.fa-rss:before {
    content: "\f09e";
}

.fa-rss-square:before {
    content: "\f143";
}

.fa-save:before {
    content: "\f0c7";
}

.fa-search:before {
    content: "\f002";
}

.fa-search-filter:before {
    content: "\e91d";
}

.fa-search-minus:before {
    content: "\f010";
}

.fa-search-plus:before {
    content: "\f00e";
}

.fa-search-simple:before {
    content: "\e95f";
}

.fa-seats:before {
    content: "\e960";
}

.fa-send:before {
    content: "\f1d8";
}

.fa-send-o:before {
    content: "\f1d9";
}

.fa-service:before {
    content: "\e936";
}

.fa-share:before {
    content: "\e926";
}

.fa-share-alt:before {
    content: "\f1e0";
}

.fa-share-arrow:before {
    content: "\e929";
}

.fa-share-square:before {
    content: "\e961";
}

.fa-sign-in:before {
    content: "\f090";
}

.fa-sign-out:before {
    content: "\f08b";
}

.fa-sitemap:before {
    content: "\f0e8";
}

.fa-sms:before {
    content: "\e603";
}

.fa-sort:before {
    content: "\f0dc";
}

.fa-sort2:before {
    content: "\e905";
}

.fa-sort3:before {
    content: "\e962";
}

.fa-sort-amount-asc:before {
    content: "\f160";
}

.fa-sort-amount-desc:before {
    content: "\f161";
}

.fa-spanner2:before {
    content: "\e90f";
}

.fa-speedometer:before {
    content: "\e910";
}

.fa-spinner:before {
    content: "\f110";
}

.fa-square-o:before {
    content: "\f096";
}

.fa-star:before {
    content: "\f005";
}

.fa-star-half:before {
    content: "\f089";
}

.fa-star-half-empty:before {
    content: "\f123";
}

.fa-star-half-full:before {
    content: "\f123";
}

.fa-star-half-o:before {
    content: "\f123";
}

.fa-star-o:before {
    content: "\f006";
}

.fa-tablet:before {
    content: "\f10a";
}

.fa-tachometer:before {
    content: "\f0e4";
}

.fa-th:before {
    content: "\f00a";
}

.fa-th-large:before {
    content: "\f009";
}

.fa-th-list:before {
    content: "\f00b";
}

.fa-thumbs-o-down:before {
    content: "\f088";
}

.fa-thumbs-o-up:before {
    content: "\f087";
}

.fa-tick:before {
    content: "\e963";
}

.fa-tick-circle:before {
    content: "\e964";
}

.fa-tiktok:before {
    content: "\e920";
}

.fa-tiktok-square:before {
    content: "\e921";
}

.fa-times:before {
    content: "\f00d";
}

.fa-times-circle:before {
    content: "\f057";
}

.fa-times-circle-o:before {
    content: "\f05c";
}

.fa-transmission:before {
    content: "\e965";
}

.fa-trash:before {
    content: "\f1f8";
}

.fa-trash-o:before {
    content: "\f014";
}

.fa-trending:before {
    content: "\e901";
}

.fa-trophy:before {
    content: "\f091";
}

.fa-twitter:before {
    content: "\f099";
}

.fa-twitter-square:before {
    content: "\f081";
}

.fa-undo:before {
    content: "\f0e2";
}

.fa-unlink:before {
    content: "\f127";
}

.fa-unsorted:before {
    content: "\f0dc";
}

.fa-upload:before {
    content: "\f093";
}

.fa-user:before {
    content: "\f007";
}

.fa-user-circle:before {
    content: "\f2bd";
}

.fa-user-circle-o:before {
    content: "\f2be";
}

.fa-user-o:before {
    content: "\f2c0";
}

.fa-users:before {
    content: "\f0c0";
}

.fa-van:before {
    content: "\e900";
}

.fa-van-side:before {
    content: "\e939";
}

.fa-van-simple:before {
    content: "\e945";
}

.fa-warning:before {
    content: "\f071";
}

.fa-whatsapp:before {
    content: "\f232";
}

.fa-wrench:before {
    content: "\f0ad";
}

.fa-youtube:before {
    content: "\f167";
}

.fa-youtube-square:before {
    content: "\f166";
}