.quicksearch-ab .search-panel-home,
.quicksearch-ab .hero-overlay,
.quicksearch-ab .hero-title {
  display: none;
}

.quicksearch-wrapper {
  background: var(--primary);
  padding-bottom: 40px;
}

#quick-search-panel-app {
  margin-top: -280px;
  z-index: 2;
  position: relative;
}

#home:not(.quicksearch-ab) .quicksearch-wrapper,
#home:not(.quicksearch-ab) #quick-search-panel-app {
  display: none;
}

.home-title {
  color: #fff;
  font-family: Leasing, "Helvetica Neue", Helvetica, Arial, sans-serif;
  width: 1080px;
  max-width: 94%;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 28px;
  margin-bottom: 40px;
  text-shadow: 0 2px 4px rgba(0,0,0,.4);
}

.quicksearch-tabs {
  background: #fff;
  width: 1080px;
  max-width: 94%;
  margin: 0 auto;
  border-radius: 15px;
  font-size: 16px;
  overflow: hidden;
}

.quicksearch-tabs .tab-icon {
  font-size: 24px;
}

.qs-tablist {
  display: flex;
  justify-content: space-around;
  color: var(--primary);
  font-family: Leasing, "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 0px;
  font-size: 14px;
}

.qs-tab {
  padding: 16px 0;
  cursor: pointer;
  text-align: center;
  user-select: none;
  flex: 0 0 100px;
  position: relative;
}

.qs-tab:hover,
.qs-tab:focus {
  background: var(--main-bg);
}

.quicksearch-tabs .tab-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0 10px;
}

.quicksearch-tabs [role="tab"][aria-selected="true"] {
  border-bottom: 3px solid var(--tertiary);
  color: var(--tertiary);
}

.qs-tab-count {
  position: absolute;
  right: 3px;
  top: 5px;
  background: var(--tertiary);
  color: #fff;
  border: 1px solid var(--tertiary);
  font-size: 12px;
  min-width: 16px;
  width: auto;
  padding: 0;
  height: 16px;
  line-height: 13px;
  text-align: center;
  border-radius: 50px;
  font-family: Leasing, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: inline-block;
  vertical-align: middle;
}

.quicksearch-content {
  display: flex;
  flex-direction: row;
  padding: 10px 20px 20px;
  border-top: 1px solid var(--border-grey);
  margin-top: -2px;
  gap: 10px;
}

.qs-left {
  flex: 1;
}

.qs-toggle-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}

.qs-finance-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.qs-button-wrapper {
  display: flex;
  gap: 5px;
  background-color: var(--main-bg);
  padding: 5px;
  border-radius: 20px;
}

.qs-button-white {
  background: transparent;
  border-radius: 15px;
  padding: 6px 10px;
  color: var(--black);
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 14px;
  transition: none;
}

.qs-button-white.selected {
  background: var(--tertiary);
  color: #fff;
  border: 1px solid var(--tertiary);
  font-weight: 700;
}

.qs-button-white:hover,
.qs-button-white.selected:hover {
  background: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
}

.qs-button-white:not(.selected):focus {
  box-shadow: 0 0 0 2px var(--focus);
  background: #fff;
  color: inherit;
  outline: none;
}

.qs-button-white.selected:focus {
  box-shadow: 0 0 0 2px var(--focus);
  outline: none;
}

.qs-vehicle-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.qs-vehicle-wrap .dropdown {
  flex: 1 1 100%;
  position: relative;
}

.qs-vehicle-wrap .dropdown:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
  top: 18px;
  right: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--primary);
}

.qs-vehicle-wrap select {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-family: Work Sans,Gotham,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 40px;
  position: relative;
  padding: 5px 20px 5px 8px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid var(--mid-grey);
  color: var(--primary);
  background: #fff;
  text-overflow: ellipsis;
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}

.qs-right {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  justify-content: space-between;
}

.qs-search-button {
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  padding: 0 10px;
  margin: 0 5px;
  flex: 0 1 auto;
  margin-top: 0px;
}

.qs-search-button:before {
  content: "\f002";
  font-family: rgd-icons;
  margin-right: 5px;
  font-weight: normal;
  font-size: 16px;
}

.qs-selected {
  margin-top: 10px;
  margin: 0 auto;
  font-family: Leasing, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 0px;
  height: 40px;
  line-height: 40px;
  text-decoration: underline;
  cursor: pointer;
}

.qs-selected:before {
  content: "\f021";
  font-family: rgd-icons;
  margin-right: 5px;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
}

.qs-count {
  background: var(--tertiary);
  color: #fff;
  border: 1px solid var(--tertiary);
  font-size: 14px;
  min-width: 20px;
  width: auto;
  padding: 0;
  height: 20px;
  line-height: 18px;
  text-align: center;
  border-radius: 50px;
  font-family: Leasing, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.qs-panel.icon {
  display: grid;
  gap: 10px;
}

.qs-panel.icon.bodystyle {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.qs-panel.icon.fueltype {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.qs-checkbox,
.qs-radio {
  position: relative;
  user-select: none;
  width: 100%;
  height: 100%;
}

.qs-checkbox.disabled,
.qs-radio.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  position: relative;
}

.qs-checkbox.disabled::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="140%" height="100%" viewBox="0 0 100 100"><line x1="0" y1="0" x2="100" y2="100" vector-effect="non-scaling-stroke" stroke="grey" stroke-width="1" /></svg>') no-repeat;
  background-position: center;
}

.qs-checkbox.disabled label,
.qs-radio.disabled label {
  pointer-events: none;
}

.qs-checkbox label,
.qs-checkbox .item-wrapper,
.qs-radio label,
.qs-radio .item-wrapper {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.qs-checkbox label .text,
.qs-radio label .text {
  overflow: hidden;
  max-width: 88px;
  white-space: nowrap;
}

.qs-checkbox .item-wrapper,
.qs-radio .item-wrapper {
  border-radius: 8px;
  padding: 10px 5px;
  border: 1px solid var(--border-grey);
  text-align: center;
}

.qs-checkbox input,
.qs-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.qs-checkbox .checkmark,
.qs-radio .checkmark {
  height: 20px;
  width: 20px;
  border-radius: 0 5px 0 0;
  position: absolute;
  right: 0;
  top: 0;
}

.qs-checkbox input:checked ~ .item-wrapper,
.qs-radio input:checked ~ .item-wrapper {
  border-color: var(--tertiary);
}

.qs-checkbox input:checked ~ .item-wrapper .checkmark,
.qs-radio input:checked ~ .item-wrapper .checkmark {
  background-color: var(--tertiary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.qs-checkbox .checkmark:after,
.qs-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.qs-checkbox input:checked ~ .item-wrapper .checkmark:after,
.qs-radio input:checked ~ .item-wrapper .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.qs-checkbox .checkmark:after,
.qs-radio .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.qs-checkbox input:focus ~ .item-wrapper,
.qs-radio input:focus ~ .item-wrapper {
  box-shadow: 0 0 0 2px var(--focus);
}

.qs-panel.icon img {
  height: 30px;
  width: auto;
}

.qs-checkbox .item-wrapper .icon,
.qs-radio .item-wrapper .icon {
  margin-bottom: 5px;
}

/*media queries*/
@media (hover: hover) and (pointer: fine) {
  .qs-checkbox .item-wrapper:hover,
  .qs-radio .item-wrapper:hover {
    background: var(--tertiary10);
  }
}

@media (max-width: 767.98px) {
  .quicksearch-content {
    flex-direction: column;
  }

  .qs-left {
    padding: 0;
  }

  .qs-right {
    padding: 0;
  }
}

@media (min-width: 480px) {
  .qs-tablist {
    gap: 30px;
    padding: 0 20px;
    flex: 1 0 33.3333%;
    justify-content: center;
    font-size: 16px;
  }

  .quicksearch-tabs [role="tab"] {
    flex: 0 1 auto;
    padding: 16px;
  }
}

@media (min-width: 600px) {

  .quicksearch-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    max-width: 100%;
    min-height: 440px;
    margin-top: -440px;
    padding: 0;
  }

  #quick-search-panel-app {
    margin-top: 0;
    max-width: 100%;
    padding: 60px 0 40px 0;
  }

  .qs-vehicle-wrap .dropdown {
    flex: 1 0 calc(100% / 3 - 20px)
  }

  .quicksearch-tabs .tab-wrapper {
    flex-direction: row;
  }

  .qs-toggle-wrapper {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  .home-title {
    font-size: 32px;
  }

  .qs-right {
    flex: 0 0 auto;
    align-items: center;
    justify-content: flex-end;
  }

  .qs-search-button {
    flex: 0 0 auto;
    height: 40px;
    line-height: 40px;
  }

  .qs-selected {
    order: -1;
    margin: 0 0 10px 0;
  }
}

@media (min-width: 1023px) {
  .home-title {
    margin-bottom: 50px;
  }
}
