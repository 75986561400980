/* signup-panel */

.account-signup {
  display: flex;
  margin: 0 auto;
  max-width: 768px;
  justify-content: space-between;
  font-size: 14px;
}

.account-signup.footer,
.account-signup.modal,
.account-signup.login {
  display: block;
}

.account-signup.login {
  font-size: 16px;
}

.account-signup.teal-bg,
.account-signup.teal-bg.black-bg {
  color: #fff;
}

.account-signup h2 {
  margin-bottom: 30px;
}

.account-signup .heading {
  font: 600 21px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 30px;
}

.account-signup.modal p {
  font-size: 14px;
}

.account-signup.login p {
  font-size: 16px;
}

.account-signup.login p a {
  color: var(--secondary);
}

.account-signup .block1,
.account-signup .block2 {
  max-width: calc(50% - 15px);
  flex: 0 1 360px;
}

.account-signup .block2 {
  display: flex;
  flex-direction: column;
}

.account-signup i {
  font-size: 24px;
  display: inline-block;
  width: 24px;
  height: auto;
  vertical-align: top;
  margin-right: 10px;
}

.account-signup.footer .icon-list,
.account-signup.modal .icon-list,
.register .account-signup.login .icon-list {
  margin-bottom: 20px;
}

.account-signup .icon-list > li {
  padding: 8px 0;
  vertical-align: top;
}

.account-signup .icon-list > li .text-block {
  display: inline-block;
  margin-bottom: 10px;
  width: calc(100% - 50px);
}

.account-signup a {
  display: inline-block;
  padding: 0 !important;
}

.account-signup a:hover {
  text-decoration: underline;
}

.account-signup.teal-bg a {
  color: #fff;
}

.account-signup.white-bg a {
  color: var(--secondary);
}

.fcol.account-signup.black-bg a,
.account-signup.black-bg a {
  color: var(--secondary);
  display: inline-block;
}

.account-signup a.button {
  width: 100%;
  max-width: 300px;
  color: #fff !important;
  padding: 16px !important;
}

.fcol.account-signup.footer a.button {
  max-width: 270px;
}

.account-signup.modal a.cta {
  max-width: 100%;
}

.account-signup.footer a.button:hover,
.account-signup.footer a.button:focus {
  background: var(--black);
}

.account-signup .purple {
  color: var(--primary);
}

@media (max-width: 767.98px) {
  .account-signup.footer {
    margin: 0;
  }

  .fcol.account-signup.footer {
    flex: 1 1 100%;
    max-width: 100%;
    order: -1 !important;
    margin-bottom: 40px;
  }

  .account-signup.footer li {
    width: 48%;
    display: inline-block;
  }
}

@media (min-width: 600px) {
  .account-signup .block2 {
    order: -1;
  }

  .account-signup .block2 p {
    order: 3;
    margin-top: 10px;
  }

  .account-signup .desktop-hide {
    display: none;
  }
}

@media (max-width: 599.98px) {
  .account-signup {
    flex-wrap: wrap;
    padding: 0 10px;
  }

  .account-signup.footer,
  .account-signup.modal,
  .account-signup.login {
    padding: 0;
  }

  .account-signup .mobile-hide {
    display: none;
  }

  .account-signup .block1,
  .account-signup .block2 {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .account-signup li {
    width: 48%;
    display: inline-block;
  }

  .account-signup .icon-list {
    margin-bottom: 20px;
  }

  .account-signup a.button {
    max-width: 100%;
  }
}

@media (max-width: 479.98px) {
  .account-signup li {
    width: 100%;
    display: inline-block;
  }

  .account-signup h2 {
    font-size: 20px;
  }

  .account-signup.footer li {
    width: 100%;
    display: inline-block;
  }

  * .fcol.account-signup.footer a.button {
    max-width: 100%;
  }
}
