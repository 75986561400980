/* ns-carousel */

h2.ns-carousel-title {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.ns-carousel-wrapper {
  margin-bottom: 20px;
}

.ns-carousel {
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  position: relative;
  user-select: none;
}

/* inputs */

.ns-carousel-activator {
  display: none;
}

.ns-carousel input[type="checkbox"],
.ns-carousel input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  left: -1000000px;
}

/* controls */

.ns-carousel-controls {
  display: none;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: -9px;
  left: -9px;
  bottom: 0;
}

.ns-carousel-controls:first-of-type {
  justify-content: flex-end;
}

.ns-carousel-controls:last-of-type {
  justify-content: flex-start;
}

.ns-carousel-activator:first-of-type:checked ~ .ns-carousel-controls:first-of-type,
.ns-carousel-activator:nth-of-type(2):checked ~ .ns-carousel-controls:nth-of-type(2),
.ns-carousel-activator:nth-of-type(3):checked ~ .ns-carousel-controls:nth-of-type(3),
.ns-carousel-activator:nth-of-type(4):checked ~ .ns-carousel-controls:nth-of-type(4),
.ns-carousel-activator:nth-of-type(5):checked ~ .ns-carousel-controls:nth-of-type(5),
.ns-carousel-activator:nth-of-type(6):checked ~ .ns-carousel-controls:nth-of-type(6),
.ns-carousel-activator:nth-of-type(7):checked ~ .ns-carousel-controls:nth-of-type(7),
.ns-carousel-activator:nth-of-type(8):checked ~ .ns-carousel-controls:nth-of-type(8),
.ns-carousel-activator:nth-of-type(9):checked ~ .ns-carousel-controls:nth-of-type(9),
.ns-carousel-activator:nth-of-type(10):checked ~ .ns-carousel-controls:nth-of-type(10) {
  display: flex;
}

.ns-carousel-control {
  cursor: pointer;
  z-index: 1;
}

.carousel-arrow {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  transition: transform 0.2s;
  width: 36px;
  height: 36px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.carousel-arrow:hover {
  transform: scale(1.05);
}

.ns-carousel-control.backward .carousel-arrow:before {
  font: 36px "rgd-icons";
  color: var(--primary);
  content: "\f104";
  margin-right: 2px;
  margin-top: -2px;
}

.ns-carousel-control.forward .carousel-arrow:before {
  font: 36px "rgd-icons";
  color: var(--primary);
  content: "\f105";
  margin-left: 2px;
  margin-top: -2px;
}

.ns-carousel-outer {
  overflow: hidden;
  margin-left: -16px;
  margin-right: -16px;
}

.ns-carousel-inner {
  transition: transform 0.3s ease;
  white-space: nowrap;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ns-carousel-activator:first-of-type:checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(0);
}

.ns-carousel-activator:nth-of-type(2):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-100%);
}

.ns-carousel-activator:nth-of-type(3):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-200%);
}

.ns-carousel-activator:nth-of-type(4):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-300%);
}

.ns-carousel-activator:nth-of-type(5):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-400%);
}

.ns-carousel-activator:nth-of-type(6):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-500%);
}

.ns-carousel-activator:nth-of-type(7):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-600%);
}

.ns-carousel-activator:nth-of-type(8):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-700%);
}

.ns-carousel-activator:nth-of-type(9):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-800%);
}

.ns-carousel-activator:nth-of-type(10):checked ~ .ns-carousel-outer .ns-carousel-inner {
  transform: translateX(-900%);
}

.ns-carousel-item {
  padding-left: 16px;
  padding-right: 16px;
  vertical-align: top;
  white-space: normal;
  flex: 1 0 auto;
  display: flex;
}

.ns-carousel-desktop-1 {
  width: 100%;
  max-width: 100%;
}

.ns-carousel-desktop-2 {
  width: 50%;
  max-width: 50%;
}

.ns-carousel-desktop-3 {
  width: 33.3333%;
  max-width: 33.3333%;
}

.ns-carousel-desktop-4 {
  width: 25%;
  max-width: 25%;
}

.ns-carousel-desktop-5 {
  width: 20%;
  max-width: 20%;
}

.ns-carousel-card {
  background: #fff;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}

.ns-carousel-card-spacer {
  flex: 1;
}

.ns-carousel-card-body {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
}

.ns-carousel-card-section {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
}

.ns-carousel-card-title {
  font-size: 18px;
  flex: 1 0 auto;
}

.ns-carousel-card .cta {
  font: 600 16px / normal "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  background: var(--secondary);
  color: #fff;
  padding: 16px;
  transition: color 0.2s, background-color 0.2s;
  text-decoration: none;
}

.ns-carousel-card .cta:hover,
.ns-carousel-card .cta:focus {
  background: var(--primary);
}

.white-block .ns-carousel-item {
  padding: 16px;
}

.white-block .news-card {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}

.card-readmore-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.card-readmore {
  color: var(--secondary);
  font-size: 16px;
}

.news-card-publish-date {
  position: relative;
  float: right;
  font-size: 0.85em;
  color: var(--middark-grey);
  margin: 0;
}

.ns-carousel-item-link:hover .card-readmore,
.link-overlay:hover .card-readmore {
  text-decoration: underline;
}

.link-overlay:hover .news-card img {
  transform: scale(1.05) translateZ(0);
}

@media (max-width: 1199.98px) {
  .ns-carousel-laptop-1 {
    width: 90%;
    max-width: 90%;
  }

  .ns-carousel-laptopt-2 {
    width: 45%;
    max-width: 45%;
  }

  .ns-carousel-laptop-3 {
    width: 33.3333%;
    max-width: 33.3333%;
  }
}

@media (max-width: 991.98px) {
  .ns-carousel {
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;
  }

  .ns-carousel-activator:nth-of-type(n):checked ~ .ns-carousel-controls:nth-of-type(n) {
    display: none;
  }

  .ns-carousel-activator:nth-of-type(n):checked ~ .ns-carousel-outer .ns-carousel-inner {
    transform: none;
  }

  .ns-carousel-outer {
    margin-left: 0;
    margin-right: 0;
  }

  .ns-carousel-inner {
    /*    overflow-x: auto;*/
    overflow-x: hidden;
    width: auto;
    padding-left: 8px;
    padding-right: 8px;
  }

  .ns-carousel-tablet-1 {
    width: 90%;
    max-width: 90%;
  }

  .ns-carousel-tablet-2 {
    width: 45%;
    max-width: 45%;
  }

  .ns-carousel-tablet-3 {
    width: 30%;
    max-width: 30%;
  }
}

@media (max-width: 991.98px) and (-webkit-min-device-pixel-ratio: 1.25), (max-width: 991.98px) and (min-device-pixel-ratio: 1.25), (max-width: 991.98px) and (-webkit-min-device-pixel-ratio: 2.0833333333333335), (max-width: 991.98px) and (min-resolution: 200dpi), (max-width: 991.98px) and (min-resolution: 1.25dppx) {
  .ns-carousel-inner {
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .ns-carousel-item {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ns-carousel-item .deal-card .deal-body {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (max-width: 575.98px) {
  .ns-carousel-inner {
    padding-left: 0;
    padding-right: 0;
  }

  .ns-carousel-mobile-1 {
    width: 90%;
    max-width: 90%;
  }

  .ns-carousel-mobile-2 {
    width: 45%;
    max-width: 45%;
  }
}
