.search-panel-modal {
  background: #fff;
  margin: 0 auto 40px;
  padding: 20px 0;
  max-width: 94%;
  font-size: 20px;
}

.search-panel-modal h2 {
  text-align: center;
  font-size: 30px;
  color: var(--primary);
  margin: 0 0 20px 0;
}

.search-panel-modal .search-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.search-panel-modal .dropdown:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
  top: 18px;
  right: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--primary);
}

.search-panel-modal select {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  font-family: Work Sans,Gotham,Helvetica Neue,Helvetica,Arial,sans-serif;
  appearance: none;
  height: 40px;
  position: relative;
  padding: 5px 20px 5px 8px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid var(--mid-grey);
  color: var(--primary);
  background: #fff;
  text-overflow: ellipsis;
  text-align: center;
  text-align-last: center;
}

.search-panel-modal .dropdown {
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  flex: 1 1 calc(50% - 10px);
  margin-bottom: 10px;
}

.search-panel-modal .dropdown.col2 {
  flex: 1 1 120%;
}

.search-panel-modal button,
.search-panel-modal .button {
  margin: 0 5px;
  font-size: 18px;
  padding: 16px;
  flex: 1 1 100%;
}

.search-panel-modal.search-button {
  flex: 0 1 auto;
}

.search-panel-modal .search-button:before {
  content: "\f002";
  font-family: rgd-icons;
  margin-right: 5px;
  font-weight: normal;
  font-size: 16px;
}

.search-panel-modal-fydc {
  background: rgba(255, 255, 255, 1);
  width: 569px;
  max-width: 94%;
  color: var(--primary);
  padding: 20px 10px;
  border: 1px solid var(--mid-grey);
  border-radius: 10px;
  margin: 20px auto;
}

.search-panel-modal-fydc .fydc-link {
  text-align: center;
  color: var(--black);
  font-size: 16px;
}

.search-panel-modal-fydc .fydc-link p {
  color: var(--black);
  font-size: 16px;
  padding: 0;
}

.search-panel-modal-fydc .fydc-link .button {
  max-width: 260px;
  padding: 11px 16px;
  margin: 10px auto 0;
  display: block;
  width: 100%;
  height: auto;
  font-size: 18px;
  padding: 0;
  height: 50px;
  line-height: 50px;
  background: var(--primary);
}

.search-panel-modal-fydc .fydc-link .button:after {
  content: "\f105";
  font-family: rgd-icons;
  margin-left: 15px;
}

@media (max-width: 767.98px) {
  .global-search {
    display: none;
  }
}

@media (min-width: 768px) {
  .global-search {
    background: var(--tertiary);
  }

  .global-search-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .global-search-panel .dropdown {
    position: relative;
    margin-right: 8px;
    flex: 0 1 90px;
  }

  .global-search-panel .dropdown.col2 {
    flex: 0 1 150px;
  }

  .global-search-panel .dropdown:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
    top: 18px;
    right: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--primary);
  }

  .global-search-panel select {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    font-family: Work Sans,Gotham,Helvetica Neue,Helvetica,Arial,sans-serif;
    appearance: none;
    height: 40px;
    position: relative;
    padding: 5px 20px 5px 8px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    border: none;
    color: var(--primary);
    background: #fff;
    text-overflow: ellipsis;
    text-align: center;
    text-align-last: center;
  }

  .global-search-panel button,
  .global-search-panel .button {
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    flex: 0 0 auto;
    overflow: hidden;
  }

  .global-search-panel .search-button {
    flex: 0 1 auto;
    border-radius: 50px;
  }

  .global-search-panel .search-button:before {
    content: "\f002";
    font-family: rgd-icons;
    margin-right: 5px;
    font-weight: normal;
    font-size: 16px;
  }

  .global-search-panel .separator {
    padding: 0 10px;
    color: #fff;
    text-align: center;
    margin-left: 30px;
    font-size: 14px;
    font-weight: 600;
  }

  .global-search-panel .fydc {
    display: flex;
    align-items: center;
  }

  .global-search-panel .fydc .button {
    background: var(--primary);
    display: flex;
    align-items: center;
    border-radius: 50px;
  }

  .global-search-panel .fydc i {
    margin-right: 5px;
    font-size: 16px;
    transform: rotate(-10deg);
    margin-top: 1px;
  }

  .global-search-panel .fydc .button:hover,
  .global-search-panel .fydc .button:focus {
    background: var(--dark-primary);
  }
}

@media (min-width: 1079.98px) {
  .global-search-panel .separator {
    margin-left: 60px;
  }
}

@media (max-width: 920px) {
  .global-search-panel .fydc {
    display: none;
  }
}
