/* modal.css */
.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 31043;
  outline: none !important;
  top: 0;
  left: 0;
  display: none;
  filter: blur();
  background: transparent;
}

@supports (backdrop-filter: blur()) {
  .modal {
    backdrop-filter: blur(2px);
  }
}

@supports (-webkit-backdrop-filter: blur()) {
  .modal {
    -webkit-backdrop-filter: blur(2px);
  }
}

.modal-dialog {
  position: fixed;
  text-align: center;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75);
  transition: transform .4s;
}

@supports (stroke-color:transparent) {
  .modal-dialog {
    transform: translate(-50%, -50%) scale(1);
  }
}

.modal.show .modal-dialog {
  transform: translate(-50%, -50%) scale(1);
}

.modal-content {
  background: #fff;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  border-radius: 20px;
  padding-top: 20px;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.modal-body {
  background: #fff;
  flex-grow: 1;
  overflow-y: auto;
  min-height: 0;
  margin: 20px;
  padding: 0 5px;
  scrollbar-width: thin;
  scrollbar-color: var(--mid-grey) #fff;
}

.modal-body::-webkit-scrollbar {
  width: 5px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 100px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: var(--mid-grey);
  border-radius: 100px;
}

.modal-content.video {
  width: 760px;
  max-width: 100%;
  min-height: unset;
}

.modal-save-search .modal-content,
.modal-carexpert .modal-content,
.modal-discontinued .modal-content {
  width: 500px;
  text-align: left;
  padding-bottom: 0;
}

.modal-carexpert .modal-content p {
  font-size: 16px;
}

.modal-discontinued .modal-content {
  padding-top: 40px;
}

.modal-discontinued .modal-header {
  margin: 0 20px;
}

.modal-discontinued .modal-header i {
  margin: 0 auto;
  font-size: 48px;
  display: block;
  width: 48px;
  color: var(--secondary);
}

.modal-content .modal-title {
  font: 24px Leasing, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: var(--primary);
  max-width: 300px;
  margin: 20px auto;
}

.modal-content .sub-title {
  font: 18px Leasing, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: var(--primary);
  max-width: 300px;
  margin-bottom: 0.5em;
  text-align: left;
}

.modal-carexpert .modal-title {
  text-align: left;
  margin: 20px;
  padding: 0 5px;
}

.modal-discontinued .modal-title {
  text-align: center;
  margin: 10px auto 0;
}

.modal-discontinued hr {
  margin-bottom: 20px;
}

.modal-discontinued .modal-body button {
  margin: 10px auto 0;
  border-radius: 100px;
  min-width: 300px;
}

.modal-content .close {
  font: normal 28px/44px Arial, Baskerville, monospace;
  position: absolute;
  right: 10px;
  top: 0;
  width: 44px;
  height: 44px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  border: 0;
  display: block;
  padding: 0;
  z-index: 31046;
  color: var(--primary);
  transition: none;
}

.modal-content .close:hover {
  font-size: 32px;
}

.modal-body video {
  width: 100%;
  height: auto;
}

.modal-backdrop {
  background: var(--black);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modaltab {
  overflow: hidden;
  background-color: #fff;
  color: var(--primary);
  display: flex;
  margin-top: 20px;
}

.modaltab button {
  flex: 1 1 50%;
  border-radius: 0;
  color: #fff;
  background: var(--dark-grey);
  font-weight: normal;
  transition: none;
  user-select: none;
  padding: 16px 10px;
}

.modaltab button:hover {
  color: #fff;
  background: var(--primary);
}

.modaltab button.active {
  background: #fff;
  color: var(--primary);
  font-weight: bold;
}

.modaltabcontent {
  display: none;
  -webkit-animation: fadeEffect 0.3s;
  animation: fadeEffect 0.3s;
}

.modal-newsletter-exit {
  color: var(--black);
}

.modal-newsletter-exit .modal-content {
  width: 450px;
  text-align: center;
  padding-bottom: 0;
  border-radius: 20px;
  background-image: url('/media/ui/circle-tl.svg'), url('/media/ui/circle-br.svg');
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  background-size: 70px;
}

.modal-newsletter-exit .modal-body {
  background: transparent;
}

.modal-newsletter-exit .icon {
  font-size: 48px;
  text-align: center;
  margin-bottom: 10px;
}

.modal-newsletter-exit .title {
  font-size: 24px;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  margin: 0 auto 10px;
  text-align: center;
}

.modal-newsletter-exit .iconblock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.modal-newsletter-exit .news-mail-icon {
  transform: rotate(-10deg);
  align-self: flex-start;
}

.modal-newsletter-exit .news-heart-icon {
  transform: rotate(10deg);
  align-self: flex-end;
}

.modal-newsletter-exit .news-heart-icon i,
.modal-newsletter-exit .news-mail-icon i {
  font-size: 48px;
}

.modal-newsletter-exit .news-image img {
  margin: 0 auto;
}

.edge .modal-newsletter-exit .news-image img {
  pointer-events: none;
}

.modal-newsletter-exit p {
  font-size: 16px;
  color: var(--black);
}

.modal-newsletter-exit .flex {
  display: flex;
  flex-direction: column;
}

.modal-newsletter-exit .input {
  position: relative;
  margin: 10px auto;
  width: 100%;
}

.modal-newsletter-exit .forminput {
  border-radius: 100px !important;
  padding: 10px 30px 10px 20px;
}

.modal-newsletter-exit .cta {
  margin: 5px 0 10px;
}

.modal-newsletter-exit .cta button {
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  border-radius: 100px;
}

.modal-newsletter-exit .override-email {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  min-height: 25px;
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.75);
  text-align: left;
  padding: 10px 10px 10px 45px;
  min-height: 45px;
}

.modal-newsletter-exit .override-email label {
  display: inline-block;
}

.modal-newsletter-exit .override-email input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.modal-newsletter-exit .override-email .checkmark {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid var(--border-grey);
  border-radius: 3px;
}

/* Style the checkmark/indicator */
.modal-newsletter-exit .override-email .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--tertiary);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.modal-newsletter-exit .override-email input:checked ~ .checkmark:after {
  display: block;
}

@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.tel-enquiry-modal .modal-content {
  width: 768px;
  max-width: 100%;
  text-align: center;
  border-radius: 20px;
  padding-bottom: 30px;
}

.tel-enquiry-modal .modal-body {
  max-width: 480px;
  margin: 0 auto;
}

.tel-advertiser {
  position: relative;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
}

.tel-advertiser:before {
  content: "\f2bd";
  font: 16px rgd-icons;
  margin-right: 5px;
  text-decoration: none;
  display: inline-block;
  padding: 0 1px;
  text-align: center;
  color: var(--primary);
}

.tel-enquiry-modal hr {
  margin: 30px 0;
}

.tel-enquiry-modal .tel-icon:before {
  content: "\f095";
  font: 16px rgd-icons;
  margin-right: 10px;
  text-decoration: none;
  display: inline-block;
  padding: 0 1px;
  text-align: center;
  color: #fff;
}

.tel-enquiry-modal .button {
  border-radius: 50px;
  background: var(--primary);
}

.tel-enquiry-modal .button:hover,
.tel-enquiry-modal .button:focus {
  background: var(--tertiary);
}


.sendtofriend-modal .modal-content {
  width: 480px;
  max-width: 100%;
  text-align: center;
  border-radius: 20px;
  padding-bottom: 30px;
}

.sendtofriend .modal-body {
  max-width: 480px;
  margin: 0 auto;
}

.share-modal .modal-content {
  width: 480px;
  max-width: 100%;
  text-align: center;
  border-radius: 20px;
  padding-bottom: 20px;
  text-align: left;
}

.share-modal .modal-body {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.share-modal .modal-title {
  text-align: center;
}

#gallery-modal {
  max-width: 100%;
}

#gallery-modal .modal-dialog {
  max-height: 100%;
  border-radius: 0;
  transition: none;
  max-width: 100%;
  background: transparent;
  height: auto;
}

#gallery-modal .modal-content {
  padding: 2px;
  /* min-height: 400px;*/
  border-radius: 8px;
}

#gallery-modal .modal-body {
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
}

.right-modal {
  height: 100%;
}

.right-modal .modal-content {
  max-width: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  border-radius: 0;
}

.right-modal .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  left: 100%;
  right: auto;
  top: 0;
  bottom: 0;
  transform: translate(0%, 0%) scale(1);
  transition: transform 0.2s;
}

.modal.right-modal.show .modal-dialog {
  transform: translate(-100%, 0%) scale(1);
}

.right-modal .icon {
  text-align: center;
  margin-bottom: 10px;
}

.right-modal .icon i {
  font-size: 24px;
  color: #fff;
  background: var(--tertiary);
  border-radius: 50px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.right-modal h2 {
  text-align: center;
  margin-bottom: 10px;
}

.stock-modal .modal-body p {
  text-align: left;
}

.stock-modal .modal-body button,
.info-modal .modal-body button {
  margin: 10px auto 40px;
  border-radius: 100px;
  min-width: 300px;
}

.info-modal p {
  padding: 0;
  margin: 0;
}

.customise-modal .modal-title {
  margin-top: -10px
}
/*media queries*/
@media (min-width: 480px) {
  .right-modal .modal-dialog {
    width: 400px;
  }

  .modal.right-modal.show .modal-dialog {
    transform: translate(-100%, 0%) scale(1);
  }

  .share-modal .modal-body {
    padding: 0 40px;
  }
}

@media (max-width: 599.98px) {
  .modaltab button {
    flex: 1 1 auto;
  }
}

@media (max-width: 479.98px) {
  .modal.show .modal-dialog {
    background: #fff;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .modal.show .modal-content {
    border-radius: 0;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: none;
  }

  #gallery-modal .modal-content {
    border-radius: 0;
  }

  #gallery-modal .modal-dialog {
    height: 100%;
  }

  #gallery-modal .deal-page-gallery {
    height: 100%;
    justify-content: center;
  }

  .modal-body {
    margin: 20px 0 0 0;
  }
}
