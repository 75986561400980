/* core */

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.glide * {
  box-sizing: inherit;
}

.g-track {
  overflow: hidden;
}

.g-slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: auto;
}

.g-dragging {
  user-select: none;
}

.g-slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.g-slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.g-control {
  -webkit-touch-callout: none;
  user-select: none;
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}

/* theme */
.g-slider {
  position: relative;
}

.g-slide {
  display: flex;
  height: auto;
  padding: 16px;
}

.g-control {
  display: none;
}

.g-arrow {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  transition: none;
  width: 36px;
  height: 36px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
  z-index: 1;
}

.g-arrow:hover,
.g-arrow:focus {
  background: #fff;
}

.g-control .forward:before {
  font: 28px "rgd-icons";
  color: var(--primary);
  content: "\f105";
  margin-left: 2px;
  margin-top: -2px;
}

.g-control .backward:before {
  font: 28px "rgd-icons";
  color: var(--primary);
  content: "\f104";
  margin-right: 2px;
  margin-top: -2px;
}

.g-arrow-disabled {
  opacity: 0;
}

.g-swipeable {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.g-dragging {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.g-slider {
  margin-bottom: 20px;
}

.slider__bullets {
  position: absolute;
  z-index: 2;
  bottom: 26px;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}

.slider__bullet {
  background-color: hsla(0,0%,100%,.5);
  width: 16px;
  height: 16px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: background-color .3s ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0,0,0,.1);
  margin: 0 1em;
}

.slider__bullet[class*=active] {
  background-color: #fff;
}

.slider__bullet:hover {
  background: rgba(0, 0, 0, 0.5);
}

@media (min-width: 992px) {
  .g-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: -9px;
    left: -9px;
    bottom: 0;
  }

  .g-control.bottom-center {
    left: 50%;
    display: inline-flex;
    transform: translateX(-50%);
    z-index: 2;
    top: unset;
    bottom: 16px;
    right: 0;
    max-width: 300px;
    align-items: unset;
  }
}
