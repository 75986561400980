/* ldc-footer.css */
footer {
  color: #fff;
  position: relative;
}

.footer2 {
  background: var(--footer-bg);
  font-size: 14px;
  padding: 40px 0;
}

.footer2 .footer2-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.footer2 .title {
  font: 20px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--dark-grey);
}

.footer2 .fcol a {
  color: #fff;
  text-decoration: none;
  padding: 12px 0;
  display: block;
  line-height: normal;
  margin-bottom: 0.2em;
  margin-right: 16px;
}

.footer2 .fcol a:hover,
.footer2 .fcol a:focus {
  text-decoration: underline;
}

.footer2 .column-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  gap: 16px;
}

.footer2 .column-wrapper > div {
  flex: 1 1 auto;
}

.footer2 .fcol .row {
  margin-bottom: 36px;
}

.footer2 .fcol .row:last-of-type {
  margin-bottom: 0px;
}

.bestdeals-signup-footer .icon-header {
  margin-bottom: 10px;
}

.bestdeals-signup-footer .icon-header i {
  font-size: 32px;
  display: inline-block;
  width: 32px;
  height: auto;
  vertical-align: top;
  margin-right: 10px;
}

.bestdeals-signup-footer .icon-header .text-block {
  display: inline-block;
  margin-bottom: 10px;
  width: calc(100% - 48px);
}

.bestdeals-signup-footer .input {
  position: relative;
  margin-bottom: 20px;
}

.bestdeals-signup-footer .ch-validation-icon {
  height: 24px;
  width: 24px;
  top: 12px;
}

.bestdeals-signup-footer .ch-validation-icon::after {
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: inline-block;
  top: 0;
}

.bestdeals-signup-footer .override-email {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  min-height: 25px;
  margin-bottom: 20px;
}

.bestdeals-signup-footer .override-email label {
  display: inline-block;
  padding-top: 4px;
}

.bestdeals-signup-footer .override-email input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.bestdeals-signup-footer .override-email .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid var(--border-grey);
}

/* Style the checkmark/indicator */
.bestdeals-signup-footer .override-email .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--tertiary);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.bestdeals-signup-footer .override-email input:checked ~ .checkmark:after {
  display: block;
}

.bestdeals-signup-footer .cta button {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 100px;
}

.bestdeals-signup-footer .cta button:disabled {
  background-color: var(--secondary) !important;
  color: #fff;
}

.bestdeals-signup-footer .cta button:hover {
  background: var(--tertiary);
}

.bestdeals-signup-footer .cta button:focus {
  background: var(--secondary);
}

.logo-block {
  color: var(--footer-bg);
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.logo-block > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-social {
  margin-bottom: 40px;
  background: var(--midlight-grey);
  justify-content: space-around;
}

.footer-social-text {
  font-size: 21px;
  margin-right: 10px;
  margin-bottom: 0;
  font-family: "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--black);
  font-weight: normal;
}

.footer-social a {
  width: 48px;
  height: 48px;
  flex: 0 1 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.footer-social a:hover {
  text-decoration: none;
}

.footer-social a i {
  font-size: 18px;
}

.footer-social a:hover i,
.footer-social a:focus i {
  font-size: 20px;
}

.associates-logos {
  display: flex;
  flex-direction: row;
}

.associates img {
  margin-left: 10px;
}

.footer-sm-text2 {
  font-size: 12px;
  margin-bottom: 20px;
  color: var(--footer-bg);
}

.footer-sm-text2 p {
  padding: 0;
  font-size: 12px;
  line-height: normal;
  margin-bottom: 10px;
}

.solera-cap-hpi {
  white-space: nowrap;
}

.solera-cap-hpi img {
  height: 17px;
  width: auto;
  display: inline;
  margin-left: 5px;
  vertical-align: middle;
}

footer .env {
  font-weight: 600;
}

.footer2 .forminput {
  border-radius: 5px !important;
  border: none;
}

/* media queries */
@media (max-width: 767.98px) {
  .footer2 .fcol.block1 {
    flex: 1 1 100%;
    max-width: 100%;
    order: 2;
  }

  .footer2 .fcol.block2 {
    flex: 1 1 100%;
    order: 1;
    margin-bottom: 20px;
    max-width: 480px;
  }

  .footer2 li a {
    padding: 16px 0;
  }

  .logo-block {
    flex-wrap: wrap;
    margin-top: -40px;
  }

  .logo-block > div {
    flex: 1 1 100%;
  }

  .footer-social a {
    margin: 0;
    color: var(--footer-bg);
  }

  .associates {
    justify-content: center;
    flex-wrap: wrap;
  }

  .associates-text {
    font-size: 12px;
  }

  .associates img {
    margin: 0 10px;
  }
}

@media (max-width: 575.98px) {
  .logo-block.wrapper-1400 {
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .footer-social-text {
    display: none;
  }
}

@media (min-width: 576px) {
  .footer2 .fcol a {
    padding: 8px 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .footer-social {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media (min-width: 768px) {
  .footer-social {
    margin: 0;
    background: none;
  }

  .footer-social a {
    margin-right: 10px;
    background: var(--footer-bg);
    color: #fff;
  }

  .associates-text {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footer2 .fcol.block1 {
    flex: 1 1 25%;
  }

  .footer2 .fcol.block2 {
    flex: 1 1 25%;
  }
}

@media (min-width: 992px) {
  .footer2 .fcol.block1 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc((100% - 360px) / 3);
  }

  .footer2 .fcol.block2 {
    max-width: 360px;
  }
}
