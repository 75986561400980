/* header.css */

#home .global-search {
  display: none;
}

header {
  z-index: 110;
  position: relative;
  width: 100%;
  background: #fff;
}

/* usp header */
.header-carousel-container {
  font-size: 14px;
  color: #fff;
  background: var(--primary);
  padding: 5px 0;
  height: 30px;
  position: relative;
  z-index: 1;
}

.header-carousel:not(.init) {
  display: flex;
  overflow: hidden;
}

.header-carousel:not(.init) .usp-body {
  flex: 1 1 100%;
  min-width: 100%;
  height: auto;
}

.header-carousel .usp-text {
  text-align: center;
}

.header-carousel .usp-text i {
  vertical-align: middle;
  color: var(--stars);
  margin-top: -3px;
}

@media (min-width: 600px) {
  .header-carousel:not(.init) .usp-body {
    flex: 1 1 50%;
    min-width: 50%;
  }
}

@media (min-width: 992px) {
  .header-carousel:not(.init) .usp-body {
    flex: 1 1 33.3333%;
    min-width: 33.3333%;
  }
}

/* reviews summary header */
.reviewsio-widget-header {
  align-self: center;
  margin-left: 10px;
  background: #fff;
  text-align: center;
  padding: 5px;
}

.reviewsio-widget-header a {
  display: block;
  width: 100%;
  height: 100%;
}

.reviewsio-widget-header .header-rating-stars {
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  width: auto;
  display: inline-block;
  position: relative;
  vertical-align: top;
  margin-left: 5px;
}

.reviewsio-widget-header .header-rating-stars:before {
  content: "\f006 \f006 \f006 \f006 \f006";
  font-family: rgd-icons;
  color: var(--stars);
  display: block;
  width: auto;
  z-index: 0;
  letter-spacing: 5px;
  position: relative;
  font-size: 12px;
  height: 18px;
}

.reviewsio-widget-header .header-rating-stars:after {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  font-family: rgd-icons;
  color: var(--stars);
  display: block;
  width: auto;
  height: 18px;
  z-index: 1;
  letter-spacing: 5px;
}

.reviewsio-widget-header .r-stars-1:after {
  content: "\f005";
}

.reviewsio-widget-header .r-stars-2:after {
  content: "\f005 \f005";
}

.reviewsio-widget-header .r-stars-3:after {
  content: "\f005 \f005 \f005";
}

.reviewsio-widget-header .r-stars-4:after {
  content: "\f005 \f005 \f005 \f005";
}

.reviewsio-widget-header .r-stars-5::after {
  content: "\f005 \f005 \f005 \f005 \f005";
}

.reviewsio-widget-header .r-stars-0-5:after {
  content: "\f089";
}

.reviewsio-widget-header .r-stars-1-half::after {
  content: "\f005 \f123";
}

.reviewsio-widget-header .r-stars-2-half::after {
  content: "\f005 \f005 \f123";
}

.reviewsio-widget-header .r-stars-3-half::after {
  content: "\f005 \f005 \f005 \f123";
}

.reviewsio-widget-header .r-stars-4-half::after {
  content: "\f005 \f005 \f005 \f005 \f123";
}

.reviewsio-widget-header .r-stars-4-5:after {
  content: "\f005 \f005 \f005 \f005 \f089";
}

.reviewsio-logo-wrap {
  display: block;
}

.reviewsio-widget-header .reviewsio-logo-wrap img {
  display: block;
  height: 12px;
  width: 75px;
  margin: auto;
}

@media (max-width: 379.98px), (min-width: 768px) and (max-width: 959.98px) {
  .reviewsio-widget-header {
    display: none;
  }
}

.mega-menu {
  position: relative;
  width: 100%;
  background: #fff;
  color: var(--primary);
}

.primary-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: #fff;
}

.header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  width: 100%;
}

.header-left .leasing-logo {
  display: block;
  height: auto;
  transition: transform 0.1s;
}

.header-left .leasing-logo:focus,
.header-left .leasing-logo:hover {
  transform: scale(1.05) translateZ(0);
}

.header-left .leasing-logo img {
  display: block;
  width: 122px;
  height: 50px;
}

.nav-quick-search {
  line-height: normal !important;
  text-align: center;
  margin-left: auto;
  cursor: pointer;
}

.nav-quick-search:hover,
.nav-quick-search:focus {
  color: var(--secondary);
}

.nav-quick-search i {
  font-size: 20px !important;
}

.nav-quick-search .qs-text {
  font-size: 11px !important;
  font-weight: 600;
}

.burger-menu {
  display: none;
}

.primary-nav .count {
  position: absolute;
  right: -8px;
  top: -8px;
  background: var(--secondary);
  font-size: 12px;
  color: #fff;
  bottom: auto;
  left: auto;
  height: 16px;
  min-width: 16px;
  line-height: 16px;
  padding: 0 4px;
  border-radius: 8px;
  font-weight: 700;
  text-align: center;
}

.mobmenu #unreadNotificationCount {
  display: none;
}

.nav-dropdown > a {
  position: relative;
}

.site-overlay {
  opacity: 0;
  pointer-events: none;
}

.site-overlay.active {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  z-index: 101;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.mega-panel h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.mega-panel-inner {
  position: relative;
  padding: 40px;
  margin-bottom: 1px;
}

.mega-panel .title {
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--primary);
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 20px;
  font-size: 20px;
}

.menu-back-link {
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  line-height: 40px;
  height: 40px;
  font-weight: normal;
  border: 1px solid var(--mid-grey);
  padding: 0 20px;
  border-radius: 50px;
  user-select: none;
}

.menu-back-link::before {
  font: normal normal 18px "rgd-icons";
  color: var(--tertiary);
  margin-right: 10px;
  content: "\f053";
  vertical-align: middle;
  display: inline-block;
}

.menu-back-link:hover,
.menu-back-link:focus {
  box-shadow: 0 0 0 2px var(--mid-grey);
}

.mega-list > a {
  font-size: 14px;
  color: var(--primary);
  text-decoration: none;
  font-weight: normal;
  position: relative;
  transition: color 0.2s;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mega-list > a:hover,
.mega-list > a:focus {
  color: var(--secondary);
}

.mega-list-wrapper a.bold-link {
  color: var(--secondary);
  font-weight: normal;
  width: 100%;
  min-width: 100%;
  display: block;
  margin-bottom: 10px;
}

.mega-list.icon-list > a > i {
  font-size: 40px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
  color: var(--black);
}

.mega-list.icon-list > a:hover > i {
  color: #000;
}

.mega-list.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mega-list.image-list > a {
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: normal;
}

.mega-list.image-list .image-wrap {
  width: 100%;
  height: auto;
  padding: 0 20px;
}

.mega-list.image-list .image-wrap img {
  width: 100%;
  max-width: 120px;
  height: auto;
  margin: 0 auto 10px;
}

.mega-list.image-list > a > i.mega-icon {
  font-size: 72px;
  margin: 0 auto 5px;
}

.electric .mega-list > a .text-block span {
  display: block;
}

.header-right .close {
  position: absolute;
  right: 5px;
  top: 10px;
  width: 44px;
  height: 44px;
  text-align: center;
  cursor: pointer;
  display: block;
  color: var(--primary);
}

.header-right .close i {
  font-size: 28px;
  line-height: 44px;
}

.header-right .close:hover i {
  font-size: 32px;
}

.header-right-inner .new a:after {
  font-size: 10px;
  background: var(--tertiary);
  color: #fff;
  content: "TRY ME";
  padding: 2px 5px;
  border-radius: 5px;
  margin-left: 5px;
}

.nav-dropdown .submenu1 {
  display: none;
}

.mega-menu.mobmenu .nav-dropdown .submenu1 {
  display: block;
}

/* media queries */
@media (max-width: 399.98px) {
  .bodytype .mega-list > a {
    flex-basis: 50%;
  }
}

@media (max-width: 767.98px) {
  /* mobile menu */
  .burger-menu {
    display: block;
    color: var(--primary);
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    position: relative;
    margin-left: 20px;
  }

  .burger-menu i {
    font-size: 28px;
    display: inline-block;
    vertical-align: middle;
    transition: color 0.2s;
  }

  .burger-menu:hover i {
    color: var(--secondary);
  }

  .nav-dropdown > a:after {
    position: absolute;
    content: "\f105";
    font-family: rgd-icons;
    font-size: 28px;
    transition: transform 0.2s;
    right: 20px;
    top: 12px;
  }

  .mega-menu.mobmenu .mega-panel {
    visibility: hidden;
  }

  /* menu closed */
  .header-right {
    will-change: transform;
    display: block;
    position: fixed;
    left: 100%;
    background: #fff;
    padding: 0 3% 100px;
    outline: none !important;
    z-index: 31043;
    width: 100%;
    height: 100%;
    height: 100vh;
    height: 100dvh;
  }

  /* menu open */
  .mobmenu .header-right {
    transform: translateX(-100%);
    transition: transform 0.3s;
  }

  /* default */
  .mega-menu .mega-panel {
    will-change: transform;
    position: fixed;
    background: #fff;
    transition: transform 0.5s, visibility 0.5s;
    top: 0;
    padding: 0 3% 100px;
    outline: none !important;
    z-index: 31043;
    width: 100%;
    height: 100%;
    left: 100%;
  }

  .mega-menu.mobmenu .header-right.active {
    transform: translateX(-200%);
  }

  .mega-menu.mobmenu .header-right.active .mega-panel.active {
    visibility: visible;
    height: 100%;
  }

  .mega-menu.mobmenu .header-right.active .mega-panel:not(.active) {
    transform: translateX(100%);
  }

  .header-right-inner {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    margin: 75px 0 0;
    padding: 0 5px;
  }

  .header-right-inner > li {
    margin-top: 40px;
  }

  .header-right-inner .nav-electric {
    margin-top: 0;
  }

  .header-right-inner .nav-electric .submenu1 > li:first-of-type {
    border-top: 1px solid var(--mid-grey);
  }

  .header-right-inner .section-title {
    margin-bottom: 0.5em;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    color: var(--primary);
    font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 24px;
    text-decoration: none;
  }

  .header-right-inner .section-title i {
    font-size: 32px;
    display: inline-block;
    width: 32px;
    vertical-align: middle;
    margin-right: 10px;
    height: 32px;
  }

  .nav-desktop {
    display: none;
  }

  .header-right-inner .submenu1 > li,
  .header-right-inner .menu-item {
    border-bottom: 1px solid var(--mid-grey);
    margin-top: 0;
  }

  .header-right-inner .submenu1 > li > a,
  .header-right-inner .menu-item > a {
    padding: 20px 10px;
    display: block;
  }

  .header-right-inner .submenu1 > li > a:hover,
  .header-right-inner .submenu1 > li > a:focus,
  .header-right-inner .menu-item > a:hover,
  .header-right-inner .menu-item > a:focus {
    text-decoration: none;
  }

  .menu-social {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 10px 40px;
    flex-wrap: wrap;
    gap: 14px;
  }

  .menu-social > li > a {
    width: 38px;
    height: 38px;
    text-align: center;
    display: block;
    border-radius: 50%;
    border: 1px solid var(--mid-grey);
  }

  .menu-social > li > a.facebook-menu {
    color: var(--facebook);
  }

  .menu-social > li > a.instagram-menu {
    color: var(--instagram);
  }

  .menu-social > li > a.twitter-menu {
    color: var(--twitter);
  }

  .menu-social > li > a.linkedin-menu {
    color: var(--linkedin);
  }

  .menu-social > li > a.youtube-menu {
    color: var(--youtube);
  }

  .menu-social > li > a.tiktok-menu {
    color: var(--tiktok);
  }

  .menu-social i {
    font-size: 24px;
    line-height: 38px;
    transition: transform 0.1s;
  }

  .menu-social > li > a:hover i {
    transform: scale(1.1);
  }

  .mega-panel-inner {
    height: 100%;
    padding: 0 10px;
  }

  .mega-menu .header-right .mega-list-wrapper {
    height: calc(100% - 65px);
    overflow-y: auto;
  }

  .mega-list-wrapper a.bold-link {
    border-bottom: 1px solid var(--mid-grey);
    padding: 20px 10px;
    height: auto;
    margin: 0;
    font-size: 16px;
  }

  .mega-list {
    padding-bottom: 60px;
  }

  .mega-list > a {
    width: 100%;
    height: auto;
    margin: 0;
    font-size: 16px;
    border-bottom: 1px solid var(--mid-grey);
    padding: 20px 10px;
  }

  .mega-list > a:last-of-type {
    border-bottom: 0;
  }

  .mega-list.icon-list > a > i {
    margin-right: 20px;
  }

  .manufacturer .mega-list > a {
    flex-basis: 100%;
    padding: 10px 0px;
    border-bottom: 1px solid var(--mid-grey);
  }

  .bodytype .mega-list > a {
    padding: 10px 0;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
  }

  .electric .mega-list > a {
    flex-basis: 100%;
    padding: 10px 0;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
  }

  .mega-panel .close {
    right: 0;
  }

  .header-right .nav-account {
    order: -1;
    margin-top: 0;
  }

  .header-right-inner .nav-electric {
    order: 1;
  }

  .header-right-inner .nav-instock {
    order: 3;
  }

  .header-right-inner .nav-bodytype {
    order: 3;
  }

  .header-right-inner .nav-van {
    order: 6;
  }

  .header-right-inner .nav-used {
    display: none;
  }

  .header-right-inner .nav-low {
    order: 7;
  }


  .header-right-inner .nav-services {
    order: 8;
  }

  .header-right-inner .nav-news {
    order: 9;
  }

  .header-right-inner .nav-social {
    order: 10;
  }

  .nav-mobile.new:after {
    font-size: 10px;
    background: var(--tertiary);
    color: #fff;
    content: "TRY ME";
    padding: 2px 5px;
    border-radius: 5px;
    margin-left: 5px;
  }
}

@media (min-width: 400px) and (max-width: 767.98px) {
  .bodytype .mega-list > a {
    flex-basis: 33%;
  }
}


@media (min-width: 768px) {
  .mega-menu {
    z-index: 2;
  }

  .mega-panel h3,
  .mega-panel .title {
    font-size: 20px;
  }

  .header-left {
    width: auto;
  }

  .header-right {
    display: flex;
    align-items: center;
    width: auto;
    padding: 0;
  }

  .nav-mobile {
    display: none !important;
  }

  #close-navigation,
  .menu-back-link,
  .section-title,
  .nav-quick-search,
  .header-right .nav-social {
    display: none;
  }

  .header-right-inner {
    display: flex;
  }

  .header-right-inner > li {
    display: flex;
    align-items: center;
    font-weight: 600;
    text-align: center;
    list-style-type: none;
    font-size: 14px;
    padding: 0 8px;
    height: 40px;
    line-height: 40px;
    position: relative;
  }

  .header-right-inner > li:first-of-type {
    padding-left: 0;
  }

  .header-right-inner > li.nav-account {
    padding-right: 0;
  }

  .header-right-inner > li > a {
    width: 100%;
    display: block;
    color: var(--primary);
    position: relative;
    height: 100%;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    letter-spacing: -0.02em;
  }

  .header-right-inner > li:not(.nav-account):not(.nav-used) > a:before {
    content: "";
    border-bottom: 2px solid rgba(236, 113, 87, 0);
    position: absolute;
    bottom: 6px;
    transition: left 0.2s, right 0.2s, border-color 0.2s;
    z-index: 1;
    left: 50%;
    right: 50%;
  }

  .header-right-inner > li > a:hover:before,
  .header-right-inner > li > a:focus:before {
    border-bottom: 2px solid rgba(236, 113, 87, 1);
    left: 0;
    right: 0;
  }

  .header-right.active .header-right-inner > li {
    position: unset;
  }

  .nav-desktop {
    display: block;
    position: relative;
  }

  .nav-dropdown.active > a {
    color: var(--primary);
    font-weight: 700;
  }

  .nav-dropdown :not(.submenu1) .nav-desktop:after {
    position: relative;
    content: "\f107";
    font-weight: normal;
    margin-left: 4px;
    margin-top: -3px;
    vertical-align: middle;
    display: inline-block;
    right: auto;
    top: auto;
    font-size: 20px;
    font-family: rgd-icons;
    transition: transform .2s;
  }

  .nav-dropdown.active .nav-desktop:after {
    transform: rotate(-180deg);
  }

  .nav-dropdown .submenu1 {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    z-index: 1000;
    min-width: 220px;
    padding: 5px 0 0;
    font-size: 14px;
    background-color: #fff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    text-align: left;
    font-weight: normal;
    border-radius: 0 0 10px 10px;
    transform: scale(0, 0);
    visibility: hidden;
    transition: opacity 0.75s ease, transform 0.75s ease, visibility 0.75s linear;
    opacity: 0;
    transform-origin: top right;
  }

  /* account button */
  .header-right-inner .nav-account .nav-desktop {
    border: 2px solid var(--secondary);
    border-radius: 5px;
    position: relative;
    background: #fff;
    transition: border-color 0.2s;
    padding: 0 8px;
    line-height: 36px;
    cursor: pointer;
  }

  .header-right-inner .nav-account .nav-desktop:hover,
  .header-right-inner .nav-account .nav-desktop:focus {
    border: 2px solid var(--primary);
  }

  .header-right-inner .nav-account i {
    font-size: 18px;
    text-align: center;
  }

  .header-right-inner .nav-account .menutext:before {
    margin-left: 5px;
    content: "";
  }

  /* dropdown opened */
  .nav-dropdown:hover .submenu1 {
    display: block;
  }

  .nav-dropdown.active .submenu1 {
    transform: scale(1, 1);
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.75s linear;
    display: block;
  }

  .nav-dropdown .submenu1 > li {
    border-bottom: 1px solid var(--light-grey);
    height: auto;
    line-height: normal;
    padding: 0;
    margin: 0;
  }

  .nav-dropdown .submenu1 > li:last-of-type {
    border: none;
  }

  .nav-dropdown .submenu1 > li > a {
    display: block;
    padding: 15px;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    text-decoration: none;
  }

  .mega-panel-wrapper {
    width: 0;
    height: 0;
    pointer-events: none;
    overflow: hidden;
  }

  .header-right.active .mega-panel-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 60px);
    width: 100%;
    height: auto;
    overflow: hidden;
    top: 100%;
  }

  .mega-panel {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    pointer-events: none;
    background: #fff;
    width: 100%;
    background-clip: padding-box;
    text-align: left;
    z-index: -2;
    transform: translateY(-100%);
    transition: transform 0.5s, visibility 0.5s;
    visibility: hidden;
    position: relative;
  }

  .mega-panel.active {
    pointer-events: all;
    z-index: -1;
    visibility: visible;
    transform: translateX(0) translateY(0);
    transition: transform 0.5s, visibility 0.5s;
    border-radius: 0 0 20px 20px;
  }

  .mega-panel-inner .close {
    top: 30px;
  }

  .mega-list.icon-list > a {
    height: 40px;
  }

  .manufacturer .mega-list > a,
  .bodytype .mega-list > a {
    flex-basis: 25%;
  }

  .electric .mega-list {
    justify-content: space-between;
    text-align: center;
  }

  .electric .mega-list > a {
    flex-basis: 33%;
  }

  .nav-instock {
    display: none !important;
  }

  .nav-used a:after {
    position: absolute;
    top: -4px;
    right: 0;
    width: auto;
    height: 15px;
    font-size: 10px;
    background: var(--tertiary);
    color: #fff;
    content: "TRY ME";
    border-radius: 5px;
    margin-left: 5px;
    line-height: 15px;
    padding: 0 5px;
  }
}


@media (min-width: 768px) and (max-width: 959.98px) {
  .nav-account .menutext {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 799.98px) {
  .manufacturer .mega-list > a {
    flex-basis: 33%;
  }

  .header-right-inner > li {
    padding: 0 6px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .header-right-inner .nav-desktop > span {
    display: none;
  }
}
